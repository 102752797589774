import React from 'react'

const IconMapMarker = ({
    fillColor = '#000',
    width = '100%',
    className = 'currentColor',
    height = '100%',
    viewBox = "0 0 384 512",
}) => (
    <svg
        width={width}
        height={height}
        viewBox={viewBox}
        className={className}
        xmlns="http://www.w3.org/2000/svg"
    >
        <path fill={fillColor} d="M172.268 501.67C26.97 291.031 0 269.413 0 192 0 85.961 85.961 0 192 0s192 85.961 192 192c0 77.413-26.97 99.031-172.268 309.67-9.535 13.774-29.93 13.773-39.464 0z"/>
    </svg>

)

export default IconMapMarker
