import { useAppContext } from "../lib/contextLib";
import Map from "../components/MapGoogle";
import { useEffect, useState } from "react";

const MapView = () => {
    const { locations, userPosition } = useAppContext();
    const [zoomLevel, setZoomLevel] = useState(10);

    const defaultCenter = {
        // Center of Phoenix
        lat: 33.3919175,
        lng: -111.9631203
/*
        // Idaho Falls - Used for testing re-centering of map on user's location
        lat: 43.3919175,
        lng: -111.9631203
*/
    }

    useEffect( () => {
        global.trackAnalyticsPageView('map');
    }, [])

    useEffect(() => {
        if (userPosition) {
            setZoomLevel(15)
        }


    }, [userPosition]);

    const mapData = {
        center: {
            lat: defaultCenter.lat,
            lng: defaultCenter.lng
        },
        zoom: zoomLevel
    }

    if (userPosition) {
        mapData.center.lat = userPosition.latitude;
        mapData.center.lng = userPosition.longitude;

        if (userPosition.latitude !== defaultCenter.lat && userPosition.longitude !== defaultCenter.lng ) {
            mapData.zoom = 13;
        }
    }

    return (
        <div className="mapContainer">
            <div className="flex flex-col">
                <div className="map bg-gray-50 w-full text-gray-900 h-70vh">
                    <Map mapLocations={locations} mapData={mapData} />
                </div>
                <div className="mapNote text-center text-sm py-4">Click on an icon above to view information about that haunt.</div>
            </div>
        </div>
    );
}

export default MapView;
