import React from "react";

const InputField = React.forwardRef(({
  className = "",
  id = "",
  labelText = "",
  labelNote = "",
  note = "",
  value="",
  ...props
}, ref) => (
    <div className="">
        <label className="block text-gray-700 text-base font-bold mb-2" htmlFor={id}>
            {props.required === true &&(
                <>
                    <span className="mr-1 font-normal text-sm text-orange-700 italic">*</span>
                    <span className="text-orange-700">{labelText}</span>
                </>
            )}

            {props.required !== true &&(
                <>
                    {labelText}
                </>
            )}

            {labelNote &&(
                <span className="text-sm text-green-700">{labelNote}</span>
            )}
        </label>
        <input
            className={`shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-md focus:ring focus:ring-blue-100 ${className}`}
            id={id}
            type="text"
            placeholder={labelText}
            value={value}
            ref={ref}
            {...props}
        />
        {note !== "" && (
            <div className="text-sm">{note}</div>
        )}
    </div>
));

export default InputField;