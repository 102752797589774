import React, { useState } from "react";
import Icons from "./Icons";
import {
  CalendarDaysIcon,
  ClockIcon,
  MapPinIcon,
} from "@heroicons/react/24/outline";
import SocialLink from "./SocialLink";
import { useFeatureFlag } from "configcat-react";

export default function Card({ location }) {
  const [loading, setLoading] = useState(true);
  const [failed, setFailed] = useState(false);

  const featureHauntImage = useFeatureFlag("hauntImages", false).value;

  const handleImageLoad = () => {
    setLoading(false);
    setFailed(false);
  };

  const handleImageError = () => {
    setLoading(false);
    setFailed(true);
  };

  return (
    <section
      className="shadow-md rounded-md overflow-hidden bg-gray-200 text-gray-900 flex flex-col relative"
      style={{
        minHeight: "450px",
        maxHeight: featureHauntImage ? "650px" : "500px", // Adjust the maxHeight based on featureHauntImage
      }}
    >
      {featureHauntImage && !failed && location.image && (
        <div className="w-full h-56 relative">
          {loading && (
            <div className="absolute inset-0 flex items-center justify-center bg-gray-200">
              <svg
                className="animate-spin h-5 w-5"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
              >
                <circle
                  className="opacity-25"
                  cx="12"
                  cy="12"
                  r="10"
                  stroke="currentColor"
                  strokeWidth="4"
                />
                <path
                  className="opacity-75"
                  fill="currentColor"
                  d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                />
              </svg>
            </div>
          )}
          <img
            className="w-full h-56 object-cover block"
            src={`${process.env.AWS_S3_BUCKET_URL}/${location.image}`}
            alt={location.name}
            onLoad={handleImageLoad}
            onError={handleImageError}
          />
        </div>
      )}
      <div className="p-3 overflow-y-scroll" style={{ flexGrow: 2 }}>
        <div className="border-b-2 border-gray-300 pb-2">
          <div className="font-bold text-xl mb-2">{location.name}</div>
          <div className="py-2 flex flex-row">
            <CalendarDaysIcon className="w-6 h-auto mr-2" /> {location.openDate}
          </div>
          <div className="py-2 flex flex-row">
            <ClockIcon className="w-6 h-auto mr-2" /> {location.hours}
          </div>
          <div className="py-2 flex flex-row">
            <MapPinIcon className="w-6 h-auto mr-2" />{" "}
            {`${location.address.street}, ${location.address.city} ${location.address.zip}`}
          </div>
        </div>
        {location.description && (
          <div className="description my-4 whitespace-pre-wrap">
            {location.description}
          </div>
        )}
      </div>
      <footer
        // className="p-3 bg-gray-100 flex justify-between"
        className={`p-3 bg-gray-100 flex ${
          location.social ? "justify-between" : "justify-end"
        }`}
      >
        {location.social && (
          <div className="social flex justify-start gap-2">
            {location.social.facebook && (
              <SocialLink
                title="View on Facebook"
                url={location.social.facebook}
                platform="Facebook"
                fillColor="#2563EB"
                iconType="facebookSquare"
                name={location.name}
              />
            )}
            {location.social.instagram && (
              <SocialLink
                title="View on Instagram"
                url={location.social.instagram}
                platform="Instagram"
                fillColor="#text-blue-600"
                iconType="instagram"
                name={location.name}
              />
            )}
            {location.social.youtube && (
              <SocialLink
                title="View on YouTube"
                url={location.social.youtube}
                platform="YouTube"
                fillColor="#f00"
                iconType="youtube"
                name={location.name}
              />
            )}
            {location.social.website && (
              <SocialLink
                title="View website"
                url={location.social.website}
                platform="Website"
                fillColor="gray"
                iconType="globe"
                name={location.name}
              />
            )}
          </div>
        )}

        <a
          href={`https://www.google.com/maps/dir/?api=1&destination=${location.pos.lat},${location.pos.lng}`}
          target="_blank"
          className="bg-gray-800 text-white active:bg-gray-600 font-bold tracking-wider uppercase text-xs px-3 py-1.5 rounded shadow hover:shadow-md outline-none focus:outline-none ease-linear transition-all duration-150 flex justify-between items-center gap-4 w-auto hover:bg-black"
          onClick={(e) => {
            global.trackAnalyticsEvent("link", "Directions", location.name);
          }}
        >
          <Icons width={10} type="mapmarker" fillColor="orange" />
          Directions
        </a>
      </footer>
    </section>
  );
}
