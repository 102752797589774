import React, { useRef, useState, useEffect } from "react";
import { useParams, useHistory } from "react-router-dom";
import { API, Storage } from "aws-amplify";

import { onError } from "../lib/errorLib";
import LoaderButton from "../components/LoaderButton";
import {useAppContext} from "../lib/contextLib";
import InputField from "../components/InputField";
import {useFormFields} from "../lib/hooksLib";


export default function Help() {
    const history = useHistory();
    const { userInfo, myHaunt } = useAppContext();

    const [fields, handleFieldChange] = useFormFields({
        firstName: "",
        lastName: "",
        email: `${userInfo.email ?? ''}`,
        hauntName: "",
        description: ""
    });
    const [isLoading, setIsLoading] = useState(false);


    console.log('🥼 Help View')

    useEffect(() => {
        console.log('Help View useEffect');
    }, []);


    function validateForm() {
        return fields.firstName.length > 0;
    }

    async function handleSubmit(event) {
        event.preventDefault();

        setIsLoading(true);

        try {
            console.log('form submitted');
            // history.push("/");
        } catch (e) {
            onError(e);
            setIsLoading(false);
        }
    }

    return (
        // <div className="helpView">
        <div className="HelpView w-full max-w-4xl mx-auto bg-gray-50 p-2 md:p-4 flex flex-col">

                {/*<div className="w-full mx-auto">*/}
                    <form onSubmit={handleSubmit} className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4">
                        <h2 className="text-green-700 text-2xl mb-4">Report an Issue</h2>



                        <div className="flex flex-col gap-8">
                            <div className="ContactInfo flex flex-col gap-10">
                                <div className="grid grid-cols-1 md:grid-cols-2 gap-2 md:gap-8">
                                    <div>
                                        <InputField id="firstName" labelText="First Name" onChange={handleFieldChange} type={"text"} value={fields.firstName} required={true}  />
                                    </div>
                                    <div>
                                        <InputField id="lastName" labelText="Last Name" onChange={handleFieldChange} type={"text"} value={fields.lastName} required={true} />
                                    </div>
                                </div>

                                <InputField id="email" labelText="Email" onChange={handleFieldChange} type={"email"} value={fields.email} required={true} />
                            </div>

                            <div className="">
                                <label className="block text-orange-700 text-base font-bold mb-2" htmlFor="state">
                                    <span className="mr-1 font-normal text-sm text-orange-700 italic">*</span>
                                    Please describe the issue you are having
                                </label>
                                <textarea
                                    className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-md focus:ring focus:ring-blue-100"
                                    id="description"
                                    onChange={handleFieldChange}
                                    defaultValue={fields.description}
                                />
                            </div>
                        </div>

                        <div className="buttonWrapper flex justify-between">
                            <div className="" />
                            <LoaderButton
                                type="submit"
                                isLoading={isLoading}
                                disabled={!validateForm()}
                                className="w-38 focus:outline-none focus:ring focus:ring-yellow-600 border border-transparent py-2 px-5 rounded-lg shadow-sm text-center text-white bg-blue-500 hover:bg-blue-600 font-medium flex justify-center items-center gap-2 disabled:opacity-60 disabled:bg-blue-400 disabled:cursor-default"
                            >
                                Submit
                            </LoaderButton>

                        </div>
                    </form>
                {/*</div>*/}
        </div>
    );
}