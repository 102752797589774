import {useEffect} from "react";


const NotFoundView = () => {
    useEffect( () => {
        global.trackAnalyticsPageView(`/`, "404");
    }, [] )

    return (
        <div className="flex justify-center items-center">
            <h3 className="text-xl">Sorry, page not found!</h3>
        </div>
    );
}

export default NotFoundView;
