import React from "react";

const IconHaunt = ({
  style = {},
  fillColor = "#000",
  width = "100%",
  className = "",
  height = "100%",
  viewBox = "0 0 223.67 256",
}) => (
  <svg
    width={width}
    height={height}
    viewBox={viewBox}
    className={className}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      className="cls-1"
      fill={fillColor}
      d="M90.87,50,126.16,0l41.5,53.23,50,42.77h-27.5v56.22l29.3,12.92-.8-11.49,3-.65v1.63l3.75-.7v-4.68h.5v4.58l2.12-.39v-3.76h.44v3.68l10.71-2,.66,2.92-11.37,1.9v3.14H228v-3.07l-2.12.35v4.9h-.5v-4.81l-3.75.62v8.8l14.5,6.39v62.27l-3.64.87,3.64,3.45V256h-220V201.49L18.91,200l-2.75-1.49v-26l46-20.28V96H34.66L83.77,57.05,75.16,45l-3-17h13V40l5.71,10Zm50.54,152h-31v54h31ZM82.5,216.69H67.92V232H82.5Zm-20.42,0H47.5V232H62.08Zm146.42,0H193.92V232H208.5Zm-20.42,0H173.5V232h14.58ZM62.08,197H47.5v15.31H62.08Zm20.42,0H67.92v15.31H82.5Zm105.58,0H173.5v15.31h14.58Zm20.42,0H193.92v15.31H208.5ZM106.25,148.88H98.12V162h8.13Zm-11.37,0H86.75V162h8.13Zm74.37,0h-8.13V162h8.13Zm-11.37,0h-8.13V162h8.13ZM94.88,132H86.75v13.12h8.13Zm11.37,0H98.12v13.12h8.13Zm51.63,0h-8.13v13.12h8.13Zm11.37,0h-8.13v13.12h8.13ZM124.79,97.12h-8.4a10,10,0,0,0,8.4,7.79Zm11.15,0H128v7.7A10,10,0,0,0,135.94,97.12Zm-11.15-3.24V85.09a10,10,0,0,0-8.56,8.79Zm11.31,0a10,10,0,0,0-8.06-8.7v8.7Z"
      transform="translate(-16.16)"
    />
  </svg>
);

export default IconHaunt;
