import React from 'react'

const CandyCorn = ({
    style = {},
    topFill = '#fff',
    middleFill = '#f47c00',
    bottomFill = '#ffed35',
    outlineColor = '#000',
    width = '100%',
    className = '',
    height = '100%',
    viewBox = "0 0 55.29 81.93",
}) => (
    <svg
        width={width}
        height={height}
        viewBox={viewBox}
        className={className}
        xmlns="http://www.w3.org/2000/svg"
    >
        <g id="Outline">
            <path className="outline" fill={outlineColor} stroke={outlineColor} d="M77.08,75l-14-43a103.75,103.75,0,0,0-9.17-20.7c-1.13-1.82-2.54-3.23-4-3.23s-2.82,1.27-4,3.1A106.81,106.81,0,0,0,36.84,32l-14,43a15,15,0,0,0,2.33,8.56C27.79,87.19,32.28,89,37.8,89H62.13c5.53,0,10-1.81,12.63-5.44A15.08,15.08,0,0,0,77.08,75Z" transform="translate(-22.32 -7.57)"/>
        </g>
        <g id="Middle">
            <polygon className="middle" fill={middleFill} points="14.52 24.43 0.53 67.44 54.76 67.44 40.77 24.43 14.52 24.43"/>
        </g>
        <g id="Bottom">
            <path className="bottom" fill={bottomFill} d="M77.08,75a15.08,15.08,0,0,1-2.32,8.56C72.14,87.19,67.66,89,62.13,89H37.81c-5.53,0-10-1.81-12.63-5.44A15,15,0,0,1,22.85,75" transform="translate(-22.32 -7.57)"/>
        </g>
        <g id="Top">
            <path className="top" fill={topFill} d="M46,11.17c1.14-1.83,2.55-3.1,4-3.1s2.82,1.41,4,3.23A104.48,104.48,0,0,1,63.09,32H36.84A106.2,106.2,0,0,1,46,11.17Z" transform="translate(-22.32 -7.57)"/>
        </g>
    </svg>
)

export default CandyCorn
