import { BsXCircleFill } from "react-icons/bs";
import { FaCheckCircle } from "react-icons/fa";
import React, { JSX, ReactDOM } from "react";

export const passwordValidate = (fields) => {
  const regex = new RegExp(
    "^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*_=+-]).{8,}$"
  );
  return regex.test(fields.password) && regex.test(fields.confirmPassword);
};

export const passwordContainsDigit = (fields) => {
  const regex = new RegExp("[0-9]");
  return regex.test(fields.password);
};

export const passwordContainsUpperLowerCaseCharacters = (fields) => {
  // Regex to verify that there is at least a lower case and an upper case letter
  const regex = new RegExp("[a-z].*[A-Z]|[A-Z].*[a-z]");
  return regex.test(fields.password);
};

export const passwordContainsSpecialCharacter = (fields) => {
  const regex = new RegExp("[!@#$%^&*_=+-]");
  return regex.test(fields.password);
};

export const passwordLength = (fields) => {
  return fields.password.length > 7;
};

export const passwordHint = (fields) => (
  <div className="text-sm pl-2">
    <div className="">Password must contain</div>
    <div className="pl-2 flex flex-col">
      <div
        className={`flex justify-items-center items-center gap-2 ${
          passwordLength(fields) ? "text-green-700" : "text-red-900"
        } `}
      >
        {!passwordLength(fields) && <BsXCircleFill size={12} />}
        {passwordLength(fields) && <FaCheckCircle size={12} />}
        <span>8 Characters</span>
      </div>
      <div
        className={`flex justify-items-center items-center gap-2 ${
          passwordContainsDigit(fields) ? "text-green-700" : "text-red-900"
        } `}
      >
        {!passwordContainsDigit(fields) && <BsXCircleFill size={12} />}
        {passwordContainsDigit(fields) && <FaCheckCircle size={12} />}
        <span>A digit [ 0-9 ]</span>
      </div>
      <div
        className={`flex justify-items-center items-center gap-2 ${
          passwordContainsUpperLowerCaseCharacters(fields)
            ? "text-green-700"
            : "text-red-900"
        } `}
      >
        {!passwordContainsUpperLowerCaseCharacters(fields) && (
          <BsXCircleFill size={12} />
        )}
        {passwordContainsUpperLowerCaseCharacters(fields) && (
          <FaCheckCircle size={12} />
        )}
        <span>A combination of lower & uppercase letters</span>
      </div>
      <div
        className={`flex justify-items-center items-center gap-2 ${
          passwordContainsSpecialCharacter(fields)
            ? "text-green-700"
            : "text-red-900"
        } `}
      >
        {!passwordContainsSpecialCharacter(fields) && (
          <BsXCircleFill size={12} />
        )}
        {passwordContainsSpecialCharacter(fields) && (
          <FaCheckCircle size={12} />
        )}
        <span>
          A special character [{" "}
          <span className="tracking-widest">!@#$%&*^_-+</span> ]
        </span>
      </div>
    </div>
  </div>
);
