import React, {useEffect, useState} from "react";
import {Link, useHistory} from "react-router-dom";
import LoaderButton from "../components/LoaderButton";
import { useAppContext } from "../lib/contextLib";
import { useFormFields } from "../lib/hooksLib";
import { onError } from "../lib/errorLib";
import { BsEye, BsEyeSlash } from "react-icons/bs";
import { Auth } from "aws-amplify";
import { passwordHint, passwordValidate } from "../components/organisms/passwordHint";

export default function Signup() {
    const history = useHistory();
    const { userHasAuthenticated, setUserInfo } = useAppContext();
    const [fields, handleFieldChange] = useFormFields({
        email: "",
        password: "",
        confirmPassword: "",
        confirmationCode: "",
    });
    const [newUser, setNewUser] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);
    const [showResendCode, toggleResendCode] = useState(false);
    const [passwordFieldType, togglePasswordFieldType] = useState('password');

    useEffect( () => {
        global.trackAnalyticsPageView('signup');
    }, [] )

    function validateForm() {
        return (
            fields.email.length > 0 &&
            fields.password.length >= 8 &&
            fields.password === fields.confirmPassword &&
            passwordValidate(fields) // Redundant, but a safety check
        );
    }

    function validateConfirmationForm() {
        return fields.confirmationCode.length > 0;
    }

    async function handleSubmit(event) {
        event.preventDefault();

        setError('');

        setIsLoading(true);

        try {
            const newUser = await Auth.signUp({
                username: fields.email,
                password: fields.password,
            });
            setIsLoading(false);
            setNewUser(newUser);

            setTimeout( () => toggleResendCode(true), 120000);
        } catch (e) {
            setError( onError(e) );
            setIsLoading(false);
        }
    }

    async function handleConfirmationSubmit(event) {
        event.preventDefault();

        setIsLoading(true);

        try {
            await Auth.confirmSignUp(fields.email, fields.confirmationCode);
            await Auth.signIn(fields.email, fields.password);

            // Retrieve the user.id
            const uInfo = await Auth.currentUserInfo();

            // Retrieve the user's email
            const authUserInfo = await Auth.currentAuthenticatedUser();

            setUserInfo(prevState => ({...prevState, id: uInfo.id, email: authUserInfo.attributes.email}))

            userHasAuthenticated(true);

            history.push("/");
        } catch (e) {
            setError( onError(e) );
            setIsLoading(false);
        }
    }

    async function handleResendCode() {
        setIsLoading(true);

        try {
          await Auth.resendSignUp( fields.email );

          setIsLoading(false);

        } catch (error) {
            setError( onError(error) );
            setIsLoading(false);
        }
    }

    function renderConfirmationForm() {
        return (
            <form onSubmit={handleConfirmationSubmit} className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4">
                <h3 className="bg-blue-50 text-gray-400 mb-4 px-4 py-2 rounded">Note: It may take a few minutes to receive the email with your code.</h3>
                <h2 className="text-2xl text-gray-700 mb-4">Verify</h2>
                <div className="mb-4">
                    <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="confirmationCode">
                        Confirmation Code
                    </label>
                    <input
                        className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                        autoFocus={true}
                        id="confirmationCode"
                        type="tel"
                        onChange={handleFieldChange}
                        value={fields.confirmationCode}
                    />
                    <span className="text-sm text-gray-600">Please check your email for the code.</span>
                </div>

                <div className="flex justify-between justify-items-center items-center">
                    <div className="">
                        {showResendCode && (
                            <button
                                className="text-orange-500 hover:text-orange-600"
                                onClick={ () => handleResendCode() }
                                type="button"
                            >Resend code</button>
                        )}
                    </div>
                    <LoaderButton
                        isLoading={isLoading}
                        disabled={!validateConfirmationForm}
                        className="focus:outline-none focus:ring focus:ring-yellow-600 border border-transparent py-2 px-5 rounded-lg shadow-sm text-center text-white bg-blue-500 hover:bg-blue-600 font-medium flex justify-center items-center gap-2 disabled:opacity-60 disabled:bg-blue-400 disabled:cursor-default"
                    >
                        Verify
                    </LoaderButton>
                </div>
            </form>
        );
    }

    function renderForm() {
        return (
            <form onSubmit={handleSubmit} className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4">
                <h2 className="text-2xl text-gray-700 mb-4">Sign Up</h2>
                {/* Email Field */}
                <div className="mb-4">
                    <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="email">
                        Email
                    </label>
                    <input
                        className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                        autoFocus={true}
                        id="email"
                        type="email"
                        onChange={handleFieldChange}
                        value={fields.email}
                    />
                </div>

                {/* Password Field */}
                <div className="mb-4">
                    <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="password">
                        Password
                    </label>
                    <div className="relative">
                        <input
                            className="shadow appearance-none border rounded w-full py-2 px-3 pr-8 mr-2 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                            id="password"
                            type={passwordFieldType}
                            onChange={ handleFieldChange }
                            value={fields.password}
                        />

                        {passwordFieldType === "text" && (
                            <BsEye
                                size={20}
                                className="text-gray-500 absolute right-0 top-0 mt-2.5 mr-2"
                                onClick={ () => { togglePasswordFieldType('password') }}
                            />
                        )}

                        {passwordFieldType === "password" && (
                            <BsEyeSlash
                                size={20}
                                className="text-gray-500 absolute right-0 top-0 mt-2.5 mr-2"
                                onClick={ () => { togglePasswordFieldType('text') }}
                            />
                        )}

                    </div>
                        <>
                            { passwordHint(fields) }
                        </>
                </div>

                {/* Confirm Password Field */}
                <div className="mb-4">
                    <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="confirmPassword">
                        Confirm Password
                    </label>
                    <div className="relative">
                        <input
                            className="shadow appearance-none border rounded w-full py-2 px-3 pr-8 mr-2 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                            id="confirmPassword"
                            type={passwordFieldType}
                            onChange={handleFieldChange}
                            value={fields.confirmPassword}
                        />
                        {passwordFieldType === "text" && (
                            <BsEye
                                size={20}
                                className="text-gray-500 absolute right-0 top-0 mt-2.5 mr-2"
                                onClick={ () => { togglePasswordFieldType('password') }}
                            />
                        )}

                        {passwordFieldType === "password" && (
                            <BsEyeSlash
                                size={20}
                                className="text-gray-500 absolute right-0 top-0 mt-2.5 mr-2"
                                onClick={ () => { togglePasswordFieldType('text') }}
                            />
                        )}

                        {fields.password !== fields.confirmPassword && (
                            <>
                                {passwordsNotMatching()}
                            </>
                        )}
                    </div>
                </div>

                <div className="flex items-center justify-between">
                    <div className="text-sm">
                        <Link to="/login/confirm" className="text-yellow-500 hover:text-yellow-600 hover:underline">Already have a code?</Link>
                    </div>
                    <LoaderButton
                        isLoading={isLoading}
                        disabled={ !validateForm() }
                        className="focus:outline-none focus:ring focus:ring-yellow-600 border border-transparent py-2 px-5 rounded-lg shadow-sm text-center text-white bg-blue-500 hover:bg-blue-600 font-medium flex justify-center items-center gap-2 disabled:opacity-60 disabled:bg-blue-400 disabled:cursor-default"
                    >
                        Signup
                    </LoaderButton>
                </div>
            </form>
        );
    }

    const passwordsNotMatching = () => (
        <div className="text-sm text-red-900 pl-2">
            <div className="">
                Password and Confirm Password do not match.
            </div>
        </div>
    );

    return (
        <div className="signupView py-4">
            <div className="w-full max-w-sm mx-auto">
                {error && (
                    <div className="error border-red-700 bg-red-100 p-4 mb-2 text-red-900">
                        <div className=" flex gap-2 items-center">
                            <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z" />
                            </svg>
                            <h3 className="font-bold text-lg">Error</h3>
                        </div>
                        {error}
                    </div>
                )}

                {newUser === null ? renderForm() : renderConfirmationForm()}
            </div>
        </div>
    );
}
