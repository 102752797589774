import azhLogo from "../images/azhaunters-color.png";
import { useEffect } from "react";

const UnderConstructionView = () => {
  useEffect(() => {
    global.trackAnalyticsPageView(`/`, "Construction");
  }, []);

  return (
    <div className="homeView lg:w-3/4 xl:w-2/5 m-auto">
      <div className="md:grid md:grid-cols-2 w-full">
        <div className="col-span-2">
          <h2 className="px-8 text-2xl text-center md:px-0 md:text-left">
            Welcome to the Official AZHaunters' Haunt Listing site!
          </h2>
        </div>
        <div className="p-2 md:p-4 flex flex-col gap-16 md:gap-8">
          <div className="md:mt-8">
            {/*<p className="pb-4 text-3xl text-orange-600">*/}
            <div className="mt-8 w-full md:w-auto text-sm px-8 py-2 text-white rounded font-medium text-2xl text-center mb-2 bg-yellow-600">
              Re-launching Oct 1st!
            </div>

            <p className="py-4">
              We are adding dust to the cobwebs, firing up the cauldrons, and
              digging up the skeletons for 2024!
            </p>

            <div className="mt-8 w-full md:w-auto text-sm px-8 py-2 text-white rounded font-medium text-md bg-gray-700">
              <div className="text-lg font-extrabold ">Note:</div>
              If you listed your haunt last year, it has <strong>
                NOT
              </strong>{" "}
              been deleted.
            </div>
          </div>

          <div className="lg:mt-16">
            <h3 className="border-b border-gray-400 text-xl mb-2">
              About this Site
            </h3>
            <p>
              We wanted to create a site where AZ Haunters' members can list
              their "haunts", and other Haunters and the public can easily
              locate them and enjoy all the skill and passion that went into
              their creation.
            </p>
          </div>
        </div>
        <div className="my-8 flex justify-center content-center">
          <div className="flex justify-center items-start">
            <a
              href="https://www.azhaunters.com?utm_source=azhauntmaps&utm_medium=cpc&utm_campaign=azhauntmaps"
              target="_blank"
              rel="noopener noreferrer"
              onClick={(e) => {
                global.trackAnalyticsEvent(
                  "link",
                  "External",
                  "AZ Haunters (logo)"
                );
              }}
            >
              <img
                src={azhLogo}
                id="azhlogo"
                className="rounded-full w-64"
                alt="AZ Haunters Logo"
              />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UnderConstructionView;
