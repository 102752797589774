import React from "react";
import CandyCorn from "./icons/candycorn";
import Haunt from "./icons/haunt";
import MapMarker from "./icons/mapmarker";
import FacebookSquare from "./icons/facebook-square";
import Instagram from "./icons/instagram";
import Globe from "./icons/globe";
import Youtube from "./icons/youtube";

const Icon = (props) => {
  switch (props.type) {
    case "candycorn":
    case "candy":
      return <CandyCorn {...props} />;
    case "facebookSquare":
      return <FacebookSquare {...props} />;
    case "instagram":
      return <Instagram {...props} />;
    case "youtube":
      return <Youtube {...props} />;
    case "globe":
      return <Globe {...props} />;
    case "mapmarker":
      return <MapMarker {...props} />;
    case "pro":
    case "haunt":
    case "yard":
    case "display":
    case "walkThrough":
    default:
      return <Haunt {...props} />;
  }
};

export default Icon;
