import { NavLink } from "react-router-dom";

import azhLogo from "../images/azhaunters-color.png";
import { useEffect } from "react";
import RegisterCTA from "../components/RegisterCTA";
import { useAppContext } from "../lib/contextLib";

const AboutView = () => {
  const { locations, isAuthenticated, hasHaunt } = useAppContext();
  useEffect(() => {
    global.trackAnalyticsPageView(`/`, "Home");
  }, []);

  return (
    <div className="homeView lg:w-3/4 xl:w-2/5 m-auto">
      <div className="md:grid md:grid-cols-2 w-full">
        <div className="p-2 md:p-4 flex flex-col gap-16 md:gap-8">
          <h2 className="px-8 text-2xl text-center md:text-left mb-6">About</h2>

          <div className="">
            <p className="mb-4">
              We wanted to create a site where AZ Haunters' members can list
              their "haunts", and other Haunters and the public can easily
              locate them and enjoy all the skill and passion that went into
              their creation.
            </p>

            <p className="mb-4">And it’s growing every year.</p>

            <p className="mb-4">
              The AZ Haunt Map is the work of home haunter and content creator,
              Derek Young, and is the "official" haunt list for{" "}
              <a
                className="text-yellow-600"
                href="https://www.azhaunters.com?utm_source=azhauntmaps&utm_medium=cpc&utm_campaign=azhauntmaps"
                onClick={(e) => {
                  global.trackAnalyticsEvent(
                    "link",
                    "External",
                    "AZ Haunters (About Us)"
                  );
                }}
              >
                AZHaunters
              </a>
              .
            </p>

            <h2 className="text-lg mb-4">HOW DOES IT WORK:</h2>

            <p className="mb-4">
              Listings can be submitted by the{" "}
              <span className="italic">haunt owner</span> only. This is to
              ensure that the AZ Haunt Map has the owner’s permission to promote
              the haunt, but also protects the privacy of the homeowner, should
              they not want their details published. This also helps to
              guarantee that all dates, times, and descriptions are accurate.
            </p>
            {/* TODO: Finish adding this
            <p className="mb-4">
              As such, every listing is managed by its respective
              owner(s)/representatives and is grouped within one of 4 event
              categories (Yard Display, Walk Through, Haunted Show, and Paid
              Attraction), as well as what age group the event is deemed
              appropriate for (Little Ones, Teens & Adults, 21+, All Ages).
              Haunts can submit details about their event, as well as photos,
              links to their social media accounts, and websites. No portion of
              the website, its data, or any user-generated content may be
              reproduced in any form or by any means outside of the SoCal Haunt
              List website, SoCal Haunt List social channels, or SoCal Haunt
              List printed flyer.
            </p>
 */}
          </div>
        </div>
        <div className="my-8 flex justify-center content-center">
          <div className="flex justify-center items-start">
            <a
              href="https://www.azhaunters.com?utm_source=azhauntmaps&utm_medium=cpc&utm_campaign=azhauntmaps"
              target="_blank"
              rel="noopener noreferrer"
              onClick={(e) => {
                global.trackAnalyticsEvent(
                  "link",
                  "External",
                  "AZ Haunters (logo)"
                );
              }}
            >
              <img
                src={azhLogo}
                id="azhlogo"
                className="rounded-full w-64"
                alt="AZ Haunters Logo"
              />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutView;
