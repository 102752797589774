const States = [
    {
        abbr: 'AK',
        full: 'Alaska'
    }, {
        abbr: 'AL',
        full: 'Alabama'
    }, {
        abbr: 'AR',
        full: 'Arkansas'
    }, {
        abbr: 'AZ',
        full: 'Arizona'
    }, {
        abbr: 'CA',
        full: 'California'
    }, {
        abbr: 'CO',
        full: 'Colorado'
    }, {
        abbr: 'CT',
        full: 'Connecticut'
    }, {
        abbr: 'DC',
        full: 'Washington, DC'
    }, {
        abbr: 'DE',
        full: 'Delaware'
    }, {
        abbr: 'FL',
        full: 'Florida'
    }, {
        abbr: 'GA',
        full: 'Georgia'
    }, {
        abbr: 'HI',
        full: 'Hawaii'
    }, {
        abbr: 'IA',
        full: 'Iowa'
    }, {
        abbr: 'ID',
        full: 'Idaho'
    }, {
        abbr: 'IL',
        full: 'Illinois'
    }, {
        abbr: 'IN',
        full: 'Indiana'
    }, {
        abbr: 'KS',
        full: 'Kansas'
    }, {
        abbr: 'KY',
        full: 'Kentucky'
    }, {
        abbr: 'LA',
        full: 'Louisiana'
    }, {
        abbr: 'MA',
        full: 'Massachusetts'
    }, {
        abbr: 'MD',
        full: 'Maryland'
    }, {
        abbr: 'ME',
        full: 'Maine'
    }, {
        abbr: 'MI',
        full: 'Michigan'
    }, {
        abbr: 'MN',
        full: 'Minnesota'
    }, {
        abbr: 'MO',
        full: 'Missouri'
    }, {
        abbr: 'MS',
        full: 'Mississippi'
    }, {
        abbr: 'MT',
        full: 'Montana'
    }, {
        abbr: 'NC',
        full: 'North Carolina'
    }, {
        abbr: 'ND',
        full: 'North Dakota'
    }, {
        abbr: 'NE',
        full: 'Nebraska'
    }, {
        abbr: 'NH',
        full: 'New Hampshire'
    }, {
        abbr: 'NJ',
        full: 'New Jersey'
    }, {
        abbr: 'NM',
        full: 'New Mexico'
    }, {
        abbr: 'NV',
        full: 'Nevada'
    }, {
        abbr: 'NY',
        full: 'New York'
    }, {
        abbr: 'OH',
        full: 'Ohio'
    }, {
        abbr: 'OK',
        full: 'Oklahoma'
    }, {
        abbr: 'OR',
        full: 'Oregon'
    }, {
        abbr: 'PA',
        full: 'Pennsylvania'
    }, {
        abbr: 'RI',
        full: 'Rhode Island'
    }, {
        abbr: 'SC',
        full: 'South Carolina'
    }, {
        abbr: 'SD',
        full: 'South Dakota'
    }, {
        abbr: 'TN',
        full: 'Tennessee'
    }, {
        abbr: 'TX',
        full: 'Texas'
    }, {
        abbr: 'UT',
        full: 'Utah'
    }, {
        abbr: 'VA',
        full: 'Virginia'
    }, {
        abbr: 'VT',
        full: 'Vermont'
    }, {
        abbr: 'WA',
        full: 'Washington'
    }, {
        abbr: 'WI',
        full: 'Wisconsin'
    }, {
        abbr: 'WV',
        full: 'West Virginia'
    }, {
        abbr: 'WY',
        full: 'Wyoming'
    }
]

export default States