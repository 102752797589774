export function onError(error) {
  // console.log("error", error);
  let message = error.toString();

  // Auth errors
  if (!(error instanceof Error) && error.message) {
    message = error.message;
  }

  return message;
}
