import { Fragment, useState } from "react";
import { Link, NavLink, useHistory } from "react-router-dom";
import { useAppContext } from "../lib/contextLib";
import Logo from "../images/logo.png";
import { BiPlus } from "react-icons/bi";
import { Auth } from "aws-amplify";
const Nav = () => {
  const history = useHistory();
  const {
    locations,
    isAuthenticated,
    hasHaunt,
    setHasHaunt,
    setUserInfo,
    userHasAuthenticated,
    underConstruction,
  } = useAppContext();
  const [isOpen, setIsOpen] = useState(false);

  async function handleLogout() {
    await Auth.signOut();

    userHasAuthenticated(false);

    setUserInfo({});

    setHasHaunt(false);

    history.push("/login");
  }

  return (
    <nav className="bg-gray-900 mb-4">
      <div className="py-2 md:py-0 px-4 md:px-8">
        <div className="flex justify-between">
          <div className="flex space-x-4 items-center">
            <div>
              <Link
                to="/"
                className="flex items-center text-gray-400 hover:text-gray-100 gap-2"
              >
                <img
                  src={Logo}
                  alt="Haunt Map"
                  className="w-auto h-8 md:w-40 md:h-auto"
                />
              </Link>
            </div>
            {/*Primary Menu */}
            <div className="hidden md:flex items-center gap-8">
              <NavLink
                to="/"
                className="py-3 px-3 text-gray-400 hover:text-gray-100 hover:bg-green-700"
              >
                Home
              </NavLink>
              {!underConstruction && locations.length > 0 && (
                <Fragment>
                  <NavLink
                    to="/listing"
                    className="py-3 px-3 text-gray-400 hover:text-gray-100 hover:bg-green-700"
                  >
                    List View
                  </NavLink>
                  <NavLink
                    to="/map"
                    className="py-3 px-3 text-gray-400 hover:text-gray-100 hover:bg-green-700"
                  >
                    Map View
                  </NavLink>
                </Fragment>
              )}
            </div>
          </div>

          {/* Secondary / Desktop Menu */}

          {!underConstruction && (
            <div className="hidden md:flex items-center space-x-1">
              {isAuthenticated ? (
                <>
                  {!hasHaunt && (
                    <NavLink
                      to="/locations/add"
                      className="py-3 px-3 text-gray-400 hover:text-gray-100 hover:bg-green-700"
                    >
                      Add Location
                    </NavLink>
                  )}

                  {hasHaunt && (
                    <NavLink
                      to={`/profile`}
                      className="py-3 px-3 text-gray-400 hover:text-gray-100 hover:bg-green-700"
                    >
                      Profile
                    </NavLink>
                  )}
                  <button
                    onClick={handleLogout}
                    className="py-3 px-3 text-gray-400 hover:text-gray-100 hover:bg-green-700"
                  >
                    Logout
                  </button>
                </>
              ) : (
                <>
                  <NavLink to="/login" className="p-3 text-gray-400">
                    Login
                  </NavLink>
                  <NavLink
                    to="/signup"
                    className="py-1 px-3 bg-orange-500 hover:bg-orange-600 text-gray-100 hover:text-gray-50 rounded transition duration-300"
                  >
                    Signup
                  </NavLink>
                </>
              )}
            </div>
          )}

          <div className="md:hidden flex items-center">
            <button
              className="mobile-menu-button"
              onClick={() => {
                setIsOpen(!isOpen);
              }}
            >
              {isOpen && (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-6 w-6"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              )}
              {!isOpen && (
                <svg
                  className="w-6 h-6"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M4 6h16M4 12h16M4 18h16"
                  />
                </svg>
              )}
            </button>
          </div>
        </div>
      </div>

      {/* Mobile Menu */}
      <div
        className={`md:hidden flex flex-col text-right ${
          isOpen ? "" : "hidden"
        }`}
        onClick={() => {
          setIsOpen(!isOpen);
        }}
      >
        {!underConstruction && (
          <>
            <NavLink
              to="/listing"
              className="py-4 px-3 text-gray-400 hover:text-gray-100 hover:bg-green-700"
            >
              List View
            </NavLink>
            <NavLink
              to="/map"
              className="py-4 px-3 text-gray-400 hover:text-gray-100 hover:bg-green-700"
            >
              Map View
            </NavLink>
          </>
        )}

        <div className="bg-gray-800 h-px" />

        {isAuthenticated && (
          <>
            {!hasHaunt && (
              <NavLink
                to="/locations/add"
                className="py-4 px-3 text-gray-400 hover:text-gray-100 hover:bg-green-700 flex justify-end justify-items-center items-center gap-2"
              >
                Add Location <BiPlus size={16} />
              </NavLink>
            )}

            {hasHaunt && (
              // <NavLink to={`/locations/edit/${myHaunt.SK}`} className="py-3 px-3 text-gray-400 hover:text-gray-100 hover:bg-green-700">Edit Location</NavLink>
              <NavLink
                to={`/profile`}
                className="py-3 px-3 text-gray-400 hover:text-gray-100 hover:bg-green-700"
              >
                Profile
              </NavLink>
            )}

            <button
              onClick={handleLogout}
              className="py-4 px-3 text-gray-400 flex justify-end items-center gap-2"
            >
              Logout
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-4 w-4"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M17 16l4-4m0 0l-4-4m4 4H7m6 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h4a3 3 0 013 3v1"
                />
              </svg>
            </button>
          </>
        )}

        {/* Look at moving the login/sign up links to outside the mobile menu*/}
        {!isAuthenticated && (
          <>
            {/*s{" "}*/}
            <NavLink to="/login" className="py-4 px-3 text-gray-400">
              Login
            </NavLink>
            <span className="flex flex-col items-end pb-4">
              <NavLink
                to="/signup"
                className="py-2 px-3 bg-green-700 hover:bg-green-800 text-gray-100 hover:text-green-300 rounded transition duration-300"
              >
                Signup
              </NavLink>
            </span>
          </>
        )}
      </div>
    </nav>
  );
};

export default Nav;
