import React, {useEffect, useRef, useState} from "react";
import { Auth } from "aws-amplify";
import LoaderButton from "../components/LoaderButton";
import { useFormFields } from "../lib/hooksLib";
import { onError } from "../lib/errorLib";
import { BsEye, BsEyeSlash} from "react-icons/bs";
import {useAppContext} from "../lib/contextLib";

export default function ConfirmationCodeView() {
    const { userHasAuthenticated, setUserInfo } = useAppContext();
    const [fields, handleFieldChange] = useFormFields({
        confirmationCode: "",
        email: "",
        password: "",
    });
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState('');
    const [passwordFieldType, togglePasswordFieldType] = useState('password');
    const emailInput = useRef();
    const passwordInput = useRef();
    const codeInput = useRef();

    useEffect( () => {
        emailInput.current.focus();
        global.trackAnalyticsPageView(`/login/confirm`, "Send Confirmation Code");
    },[])

    function validateConfirmationForm() {
        return ( fields.email.length > 0
            && fields.password.length > 0
            && fields.confirmationCode.length === 6)
            && passwordValidate();
    }

    const passwordValidate = () => {
        const regex = new RegExp('^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*_=+-]).{8,}$');
        return regex.test(fields.password);
    }

    function renderConfirmationForm() {
        return (
            <form onSubmit={handleSubmit} className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4">
                <h2 className="text-2xl text-gray-700 mb-4">Verify Signup</h2>
                <div className="text-sm text-gray-600 mb-8">Please check your email for the code.</div>
                <div className="mb-4">
                    <div className="mb-4">
                        <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="email">
                            Email
                        </label>
                        <input
                            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                            autoFocus={true}
                            id="email"
                            type="email"
                            onChange={handleFieldChange}
                            value={fields.email}
                            required="required"
                            ref={emailInput}
                        />
                    </div>

                    <div className="mb-4">
                        <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="password">
                            Password
                        </label>
                        <div className="relative">
                            <input
                                className="shadow appearance-none border rounded w-full py-2 px-3 pr-8 mr-2 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                id="password"
                                type={passwordFieldType}
                                onChange={ handleFieldChange }
                                value={fields.password}
                                required="required"
                                ref={passwordInput}
                            />

                            {passwordFieldType === "text" && (
                                <BsEye
                                    size={20}
                                    className="text-gray-500 absolute right-0 top-0 mt-2.5 mr-2"
                                    onClick={ () => { togglePasswordFieldType('password') }}
                                />
                            )}

                            {passwordFieldType === "password" && (
                                <BsEyeSlash
                                    size={20}
                                    className="text-gray-500 absolute right-0 top-0 mt-2.5 mr-2"
                                    onClick={ () => { togglePasswordFieldType('text') }}
                                />
                            )}
                        </div>
                    </div>

                    <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="confirmationCode">
                        Confirmation Code
                    </label>
                    <input
                        className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                        autoFocus={true}
                        id="confirmationCode"
                        type="tel"
                        onChange={handleFieldChange}
                        value={fields.confirmationCode}
                        required="required"
                        pattern="[0-9]{6}"
                        ref={codeInput}
                    />
                    <div className="text-sm text-gray-600">Numbers only</div>
                </div>

                <div className="flex justify-end justify-items-center items-center">
                    <LoaderButton
                        type="submit"
                        isLoading={isLoading}
                        disabled={!validateConfirmationForm()}
                        className="focus:outline-none focus:ring focus:ring-yellow-600 border border-transparent py-2 px-5 rounded-lg shadow-sm text-center text-white bg-blue-500 hover:bg-blue-600 font-medium flex justify-center items-center gap-2 disabled:opacity-60 disabled:bg-blue-400 disabled:cursor-default"
                    >
                        Verify
                    </LoaderButton>
                </div>
            </form>
        );
    }

    async function handleSubmit(event) {
        event.preventDefault();

        setIsLoading(true);

        try {
            await Auth.confirmSignUp(fields.email, fields.confirmationCode);
            await Auth.signIn(fields.email, fields.password);

            // Retrieve the user.id
            const uInfo = await Auth.currentUserInfo();

            // Retrieve the user's email
            const authUserInfo = await Auth.currentAuthenticatedUser();

            setUserInfo(prevState => ({...prevState, id: uInfo.id, email: authUserInfo.attributes.email}))

            userHasAuthenticated(true);

            history.push("/");
        } catch (e) {
            setError( onError(e) );
            setIsLoading(false);
        }
    }

    return (
        <div className="SubmitConfirmationCode py-4">
            {error && (
                <div className="error border-red-700 bg-red-100 text-gray-900 p-4 mb-2">
                    <div className=" flex gap-2 items-center">
                        <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z" />
                        </svg>
                        <h3 className="font-bold text-lg">Error</h3>
                    </div>
                    {error}
                </div>
            )}
            <div className="w-full max-w-sm mx-auto bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4">
                { renderConfirmationForm() }
            </div>
        </div>
    );
}
