import React, { useEffect, useRef, useState } from "react";
import { NavLink, useHistory } from "react-router-dom";
import LoaderButton from "../components/LoaderButton";
import { onError } from "../lib/errorLib";
import { useFormFields } from "../lib/hooksLib";
import { API } from "aws-amplify";
import States from "../components/States";
import InputField from "../components/InputField";
import { useAppContext } from "../lib/contextLib";
import { toast } from "react-toastify";
import { SocialLinks } from "social-links";

const AddLocation = () => {
  const socialLinks = new SocialLinks({
    usePredefinedProfiles: true,
    trimInput: true,
    allowQueryParams: false,
  });
  const { userInfo, setReloadLocations } = useAppContext();

  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  const [fields, handleFieldChange] = useFormFields({
    firstName: "",
    lastName: "",
    email: `${userInfo.email ?? ""}`,
    cognitoEmail: `${userInfo.email ?? ""}`,
    address: "",
    city: "",
    state: "AZ",
    zipcode: "",
    hauntType: "",
    hauntName: "",
    description: "",
    openingDate: "",
    hours: "",
    website: "",
    facebook: "",
    instagram: "",
    /* Add YouTube
    youtube: "",
    */
  });

  const [step, setStep] = useState(1);

  const form = useRef();
  const urlInput = useRef();

  useEffect(() => {
    global.trackAnalyticsPageView(`/add`, "Add Haunt Location");
  }, []);

  const totalSteps = 4;

  function validateContactInfo() {
    return (
      fields.firstName.length > 0 &&
      fields.lastName.length > 0 &&
      fields.email.length > 0 &&
      validateEmail(fields.email)
    );
  }

  const sanitizeSocial = (e, platform = null) => {
    if (
      platform &&
      e.target.value.length > 0 &&
      socialLinks.isValid(platform, e.target.value)
    ) {
      e.target.value = socialLinks.sanitize(platform, e.target.value);
      handleFieldChange(e);
    }
  };

  function validateSocial() {
    if (fields.website.length > 0 && !urlInput.current.validity.valid) {
      return false;
    }

    if (
      fields.instagram.length > 0 &&
      !socialLinks.isValid("instagram", fields.instagram)
    ) {
      return false;
    }

    if (
      fields.facebook.length > 0 &&
      !socialLinks.isValid("facebook", fields.facebook)
    ) {
      return false;
    }

    /* Add YouTube
    if (
      fields.youtube.length > 0 &&
      !socialLinks.isValid("youtube", fields.facebook)
    ) {
      return false;
    }
    */

    return true;
  }

  function validateForm() {
    return (
      fields.address.length > 0 &&
      fields.city.length > 0 &&
      fields.zipcode.length > 0 &&
      fields.firstName.length > 0 &&
      fields.lastName.length > 0 &&
      fields.openingDate.length > 0 &&
      fields.hauntType.length > 0
    );
  }

  function validateAddressInfo() {
    return (
      fields.address.length > 0 &&
      fields.city.length > 0 &&
      fields.zipcode.length > 0 &&
      fields.state.length > 0
    );
  }

  function formatDate(dateString) {
    const months = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];

    const fDate = new Date(dateString);
    return `${months[fDate.getMonth()]} ${fDate.getDate() + 1}`;
  }

  function validateHauntInfo() {
    return fields.hauntType.length > 0 && fields.openingDate.length > 0;
  }

  function validateEmail(email) {
    const re =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }

  const capitalizeWords = (text) => {
    return text
      .split(" ")
      .map((word) => {
        return word[0].toUpperCase() + word.substring(1);
      })
      .join(" ");
  };

  async function handleSubmit(event) {
    event.preventDefault();

    setIsLoading(true);

    const formattedDate = formatDate(fields.openingDate);

    console.log("formattedDate", formattedDate);

    try {
      const locationObj = {
        contact: {
          firstName: fields.firstName.trim(),
          lastName: fields.lastName.trim(),
          email: fields.email.trim(),
          cognitoEmail: fields.cognitoEmail,
        },
        address: {
          street: capitalizeWords(fields.address.trim()),
          city: capitalizeWords(fields.city.trim()),
          state: fields.state.trim(),
          zip: fields.zipcode.trim(),
        },
        name: fields.hauntName.trim(),
      };

      if (fields.description) {
        locationObj.description = fields.description.trim();
      }

      if (fields.hauntType) {
        locationObj.type = fields.hauntType;
      }

      if (fields.hauntName.length === 0) {
        locationObj.name =
          fields.hauntType.charAt(0).toUpperCase().trim() +
          fields.hauntType.slice(1) +
          " Haunt";
      }

      if (fields.openingDate) {
        locationObj.openDate = formattedDate;
      }

      if (fields.hours) {
        locationObj.hours = fields.hours.trim();
      }

      if (
        fields.website ||
        fields.facebook ||
        fields.instagram
        /* Add YouTube
        || fields.youtube
        */
      ) {
        locationObj.social = {};

        if (fields.website) {
          locationObj.social.website = fields.website.trim();
        }
        if (fields.facebook) {
          locationObj.social.facebook = fields.facebook.trim();
        }
        if (fields.instagram) {
          locationObj.social.instagram = fields.instagram.trim();
        }
        /* Add YouTube
        if (fields.youtube) {
          locationObj.social.youtube = fields.youtube.trim();
        }
        */
      }
      await addLocation(locationObj);

      setIsLoading(false);
      setReloadLocations(true);
      history.push("/");

      toast.success("Successfully added Location 👻");
    } catch (e) {
      if (e.step) {
        setStep(2);
      }
      setError(onError(e));
      setIsLoading(false);
    }
  }

  function addLocation(locationInfo) {
    return API.post("locations", "/locations", {
      body: locationInfo,
    })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        if (error.response.status === 404) {
          throw {
            message:
              "Unable to find the address you entered.  Please check and try again.",
            step: 2,
          };
        }
        if (error.status !== 404) {
          throw error;
        }
      });
  }

  function renderError() {
    return (
      <div className="error border-red-700 bg-red-100 text-gray-900 p-4 mb-2 text-red-900">
        <div className=" flex gap-2 items-center">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-6 w-6"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z"
            />
          </svg>
          <h3 className="font-bold text-lg">Error</h3>
        </div>
        {error}
      </div>
    );
  }

  return (
    <div className="AddLocation w-full max-w-4xl mx-auto bg-gray-50 p-2 md:p-4 flex flex-col">
      {error && renderError()}
      <form
        onSubmit={handleSubmit}
        className="bg-white shadow-md rounded px-2 md:px-4 md:pt-6 text-gray-400"
        noValidate
        ref={form}
      >
        <div className="flex flex-col md:flex-row justify-between md:mb-8">
          <h2 className="text-yellow-600 text-3xl">Add Haunt Location</h2>
          <div className="mt-4 flex flex-col md:mt-2 md:items-end">
            <div className="uppercase tracking-wide text-xs font-bold text-gray-500 leading-tight">
              Step: {step} of {totalSteps}
            </div>
            <div className="flex items-center md:w-48">
              <div className="w-full bg-gray-200 rounded-full mr-2">
                <div
                  className="rounded-full bg-green-500 text-xs leading-none h-2 text-center text-white"
                  style={{ width: parseInt((step / totalSteps) * 100) + "%" }}
                />
              </div>
              <div className="text-xs w-10 text-gray-600 text-right">
                {" "}
                {parseInt((step / totalSteps) * 100) + "%"}{" "}
              </div>
            </div>
          </div>
        </div>

        {/* Form Submitted Confirmation */}
        {step == "complete" && (
          <div>
            <div className="max-w-3xl mx-auto px-4 py-10">
              <div>
                <div className="bg-white rounded-lg p-10 flex flex-col items-center shadow justify-between">
                  <svg
                    className="mb-4 h-20 w-20 text-green-500 mx-auto"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                  >
                    <path
                      fillRule={"evenodd"}
                      d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                      clipRule={"evenodd"}
                    />
                  </svg>

                  <h2 className="text-2xl mb-4 text-gray-800 text-center font-bold">
                    Haunt Successfully Added
                  </h2>

                  <div className="text-gray-600 mb-8">
                    Thank you. We have added your haunt to the application.
                  </div>

                  <NavLink
                    to="/"
                    className="w-40 block mx-auto focus:outline-none py-2 px-5 rounded-lg shadow-sm text-center text-gray-600 bg-white hover:bg-gray-100 font-medium border"
                  >
                    Back to home
                  </NavLink>
                </div>
              </div>
            </div>
          </div>
        )}

        {step != "complete" && (
          <>
            <div className="border-b my-6">
              <div className="flex flex-col md:flex-row md:items-center md:justify-between mb-2">
                <div className="flex-1">
                  {step === 1 && (
                    <div className="text-xl md:text-2xl text-gray-700 leading-tight">
                      Contact Information
                    </div>
                  )}

                  {step === 2 && (
                    <div className="text-2xl text-gray-700 leading-tight">
                      Address
                    </div>
                  )}

                  {step === 3 && (
                    <div className="text-2xl text-gray-700 leading-tight">
                      Haunt Information
                    </div>
                  )}

                  {step === 4 && (
                    <div className="text-2xl text-gray-700 leading-tight">
                      Sharing | Social Media
                    </div>
                  )}
                </div>
              </div>
            </div>

            <div className="p-2 md:px-4 md:pt-8 md:pb-4">
              {/* STEP 1 - CONTACT INFORMATION */}
              {step === 1 && (
                <div className="flex flex-col gap-10">
                  <div className="grid grid-cols-1 md:grid-cols-2 gap-2 md:gap-8">
                    <div>
                      <InputField
                        id="firstName"
                        labelText="First Name"
                        onChange={handleFieldChange}
                        type={"text"}
                        value={fields.firstName}
                        required={true}
                      />
                    </div>
                    <div>
                      <InputField
                        id="lastName"
                        labelText="Last Name"
                        onChange={handleFieldChange}
                        type={"text"}
                        value={fields.lastName}
                        required={true}
                      />
                    </div>
                  </div>

                  <InputField
                    id="email"
                    labelText="Email"
                    onChange={handleFieldChange}
                    type={"email"}
                    value={fields.email}
                    required={true}
                  />
                </div>
              )}

              {/* STEP 2 - ADDRESS INFORMATION */}
              {step === 2 && (
                <div className="flex flex-col gap-10">
                  <div className="grid grid-cols-1">
                    <InputField
                      id="address"
                      labelText="Street Address"
                      onChange={handleFieldChange}
                      type={"text"}
                      value={fields.address}
                      required={true}
                    />
                  </div>

                  <div className="grid grid-cols-3 gap-8">
                    <div>
                      <InputField
                        id="city"
                        labelText="City"
                        onChange={handleFieldChange}
                        type={"text"}
                        value={fields.city}
                        required={true}
                      />
                    </div>
                    <div>
                      <label
                        className="block text-orange-700 text-base font-bold mb-2"
                        htmlFor="state"
                      >
                        <span className="mr-1 font-normal text-sm text-orange-700 italic">
                          *
                        </span>
                        State
                      </label>
                      <select
                        name="state"
                        id="state"
                        className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-md focus:ring focus:ring-blue-100"
                        onChange={handleFieldChange}
                        value={fields.state}
                      >
                        <option key={"--"} value="0">
                          --
                        </option>
                        {States.map((st) => (
                          <option key={st.abbr} value={st.abbr}>
                            {st.full}
                          </option>
                        ))}
                      </select>
                    </div>
                    <div>
                      <InputField
                        id="zipcode"
                        labelText="Zip Code"
                        onChange={handleFieldChange}
                        type={"text"}
                        value={fields.zipcode}
                        required={true}
                      />
                    </div>
                  </div>
                </div>
              )}

              {/* STEP 3 - HAUNT INFORMATION */}
              {step === 3 && (
                <div className="flex flex-col gap-10">
                  {/* Type of Haunt */}
                  <div className="">
                    <label
                      className="block text-orange-700 text-base font-bold mb-2"
                      htmlFor="hauntType"
                    >
                      <span className="mr-1 font-normal text-sm text-orange-700 italic">
                        *
                      </span>
                      What type of Haunt do you have?
                    </label>
                    <select
                      id="hauntType"
                      className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-md focus:ring focus:ring-blue-100"
                      onChange={handleFieldChange}
                      value={fields.hauntType}
                      required={true}
                    >
                      <option key="---" value="">
                        --
                      </option>
                      <option key="walkThrough" value="walkThrough">
                        Walk through
                      </option>
                      <option key="display" value="display">
                        Display
                      </option>
                      <option key="pro" value="pro">
                        Professional
                      </option>
                    </select>
                  </div>

                  <div className="grid grid-cols-1 gap-8 md:grid-cols-2">
                    <div>
                      <InputField
                        id="openingDate"
                        labelText="Opening Date"
                        onChange={handleFieldChange}
                        type={"date"}
                        value={fields.openingDate}
                        required={true}
                      />
                    </div>
                    <div>
                      <InputField
                        id="hours"
                        labelText="Hours"
                        onChange={handleFieldChange}
                        type={"text"}
                        value={fields.hours}
                        note="example: 7pm-10pm"
                      />
                    </div>
                  </div>

                  <InputField
                    id="hauntName"
                    labelText="Haunt Name"
                    onChange={handleFieldChange}
                    type={"text"}
                    value={fields.hauntName}
                    note={
                      <>
                        (if you have one) This will be displayed in the
                        information box on the list/map view.
                      </>
                    }
                  />

                  <div className="">
                    <label
                      className="block text-gray-700 text-sm font-bold mb-2"
                      htmlFor="description"
                    >
                      Short Description
                    </label>
                    <textarea
                      className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-md focus:ring focus:ring-blue-100"
                      id="description"
                      onChange={handleFieldChange}
                      defaultValue={fields.description}
                    />
                  </div>
                </div>
              )}

              {/* STEP 4 - SOCIAL MEDIA / WEBSITE */}
              {step === totalSteps && (
                <div className="flex flex-col gap-10">
                  <InputField
                    id="website"
                    labelText="Website URL"
                    type={"url"}
                    value={fields.website}
                    onChange={handleFieldChange}
                    ref={urlInput}
                    note={
                      fields.website.length > 0 &&
                      !urlInput.current.validity.valid && (
                        <span className="text-red-800">
                          Invalid URL, please check and try again
                        </span>
                      )
                    }
                  />

                  <InputField
                    id="facebook"
                    labelText="Facebook URL"
                    onChange={handleFieldChange}
                    type={"url"}
                    value={fields.facebook}
                    onBlur={(e) => {
                      sanitizeSocial(e, "facebook");
                    }}
                    note={
                      fields.facebook.length > 0 &&
                      !socialLinks.isValid("facebook", fields.facebook) && (
                        <span className="text-red-800">
                          Invalid Facebook URL, please check and try again
                        </span>
                      )
                    }
                  />

                  <InputField
                    id="instagram"
                    labelText="Instagram URL"
                    onChange={handleFieldChange}
                    type={"url"}
                    value={fields.instagram}
                    onBlur={(e) => {
                      sanitizeSocial(e, "instagram");
                    }}
                    note={
                      fields.instagram.length > 0 &&
                      !socialLinks.isValid("instagram", fields.instagram) && (
                        <span className="text-red-800">
                          Invalid Instagram URL, please check and try again
                        </span>
                      )
                    }
                  />

                  {/* Add YouTube
                  <InputField
                    id="youtube"
                    labelText="YouTube URL"
                    onChange={handleFieldChange}
                    type={"url"}
                    value={fields.youtube}
                    onBlur={(e) => {
                      sanitizeSocial(e, "youtube");
                    }}
                    note={
                      fields.youtube.length > 0 &&
                      !socialLinks.isValid("youtube", fields.youtube) && (
                        <span className="text-red-800">
                          Invalid YouTube URL, please check and try again
                        </span>
                      )
                    }
                  />
                  */}
                </div>
              )}
            </div>
          </>
        )}

        {step != "complete" && (
          <div className="md:px-4 flex justify-between my-8">
            <div className="">
              {step > 1 && (
                <button
                  type={"button"}
                  onClick={() => {
                    setStep(parseInt(step) - 1);
                  }}
                  className="w-32 focus:outline-none py-2 px-5 rounded-lg shadow-sm text-center text-gray-600 bg-white hover:bg-gray-100 font-medium border focus:outline-none focus:shadow-md focus:ring focus:ring-blue-100"
                >
                  Previous
                </button>
              )}
            </div>

            <div className="">
              {step < totalSteps && (
                <>
                  {step == 1 && (
                    <button
                      disabled={!validateContactInfo()}
                      onClick={() => {
                        setStep(parseInt(step) + 1);
                      }}
                      className="w-38 focus:outline-none focus:ring focus:ring-yellow-600 border border-transparent py-2 px-5 rounded-lg shadow-sm text-center text-white bg-blue-500 hover:bg-blue-600 font-medium flex justify-center items-center gap-2 disabled:opacity-60 disabled:bg-blue-400 disabled:cursor-default"
                    >
                      Address
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="h-4 w-4"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth={2}
                          d="M9 5l7 7-7 7"
                        />
                      </svg>
                    </button>
                  )}

                  {step == 2 && (
                    <button
                      disabled={!validateAddressInfo()}
                      onClick={() => {
                        setError(null);
                        setStep(parseInt(step) + 1);
                      }}
                      className="w-38 focus:outline-none focus:ring focus:ring-yellow-600 border border-transparent py-2 px-5 rounded-lg shadow-sm text-center text-white bg-blue-500 hover:bg-blue-600 font-medium flex justify-center items-center gap-2 disabled:opacity-60 disabled:bg-blue-400 disabled:cursor-default"
                    >
                      Information
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="h-4 w-4"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth={2}
                          d="M9 5l7 7-7 7"
                        />
                      </svg>
                    </button>
                  )}
                  {step == 3 && (
                    <button
                      disabled={!validateHauntInfo()}
                      onClick={() => {
                        setStep(parseInt(step) + 1);
                      }}
                      className="w-38 focus:outline-none focus:ring focus:ring-yellow-600 border border-transparent py-2 px-5 rounded-lg shadow-sm text-center text-white bg-blue-500 hover:bg-blue-600 font-medium flex justify-center items-center gap-2 disabled:opacity-60 disabled:bg-blue-400 disabled:cursor-default"
                    >
                      Social
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="h-4 w-4"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth={2}
                          d="M9 5l7 7-7 7"
                        />
                      </svg>
                    </button>
                  )}
                </>
              )}
              {step == totalSteps && (
                <LoaderButton
                  isLoading={isLoading}
                  disabled={!validateForm() || !validateSocial()}
                  className="w-38 focus:outline-none focus:ring focus:ring-yellow-600 border border-transparent py-2 px-5 rounded-lg shadow-sm text-center text-white bg-blue-500 hover:bg-blue-600 font-medium flex justify-center items-center gap-2 disabled:opacity-60 disabled:bg-blue-400 disabled:cursor-default"
                  onClick={handleSubmit}
                >
                  Add Location
                </LoaderButton>
              )}
            </div>
          </div>
        )}
        {/* End Bottom Nav*/}
      </form>
    </div>
  );
};

export default AddLocation;
