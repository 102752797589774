import React, { Fragment, useEffect, useState } from "react";
import { API } from "aws-amplify";
import { NavLink, useHistory } from "react-router-dom";
import { onError } from "../lib/errorLib";
import { useAppContext } from "../lib/contextLib";
import Icons from "../components/Icons";
import LoaderButton from "../components/LoaderButton";
import { Dialog, Transition } from "@headlessui/react";
import { ExclamationTriangleIcon } from "@heroicons/react/24/outline";
import { toast } from "react-toastify";
import { useFeatureFlag } from "configcat-react";

import CustomDialog from "../components/modals/customDialog";
// import ReactivateModal from "../components/modals/reactivate";
import openReactivateDialog from "../components/modals/reactivate";
// import openDeactivateDialog from "../components/modals/deactivate";

// Function to set a cookie
function setCookie(name, value, minutes) {
  const date = new Date();
  date.setTime(date.getTime() + minutes * 60 * 1000);
  const expires = "expires=" + date.toUTCString();
  document.cookie = name + "=" + value + ";" + expires + ";path=/";
}

// Function to get a cookie
function getCookie(name) {
  const nameEQ = name + "=";
  const ca = document.cookie.split(";");
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) === " ") c = c.substring(1, c.length);
    if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length);
  }
  return null;
}

export default function Profile() {
  const history = useHistory();
  const { hasHaunt, setHasHaunt, myHaunt, setMyHaunt, setReloadLocations } =
    useAppContext();

  //   Where does the loading happen?  App.js?
  const [isLoading, setIsLoading] = useState(true);

  const [isEditing, setIsEditing] = useState(false);

  const [isDeletingImage, setIsDeletingImage] = useState(false);
  const [isAddingImage, setIsAddingImage] = useState(false);

  const [isDeleting, setIsDeleting] = useState(false);
  const [modalOpen, setModalState] = useState(true);

  const featureHauntImages = useFeatureFlag("hauntImages", false).value;

  const [dialogContent, setDialogContent] = useState({ title: "", body: "" });

  const [isDialogOpen, setIsDialogOpen] = useState(false);

  useEffect(() => {
    if (myHaunt) {
      if (!myHaunt.isActive) {
        const cookie = getCookie("welcomeBackDialogShown");

        // Check to see if the user has previously clicked "No" to reactivating their haunt, if so, then don't display the dialog.  WOuld I use a cookie with a short expiration?
        if (cookie !== "true") {
          openWelcomeBackDialog();
        }
      }

      setIsLoading(false);
    }
  }, [myHaunt]);

  // If the user does not have a haunbt, then we need to set isLoading to false so the "add haunt" CTA is displayed.
  useEffect(() => {
    if (!hasHaunt) {
      setIsLoading(false);
    }
  }, []);

  // OLD CODE... Should be moved to deactive.js
  const openDeactivateDialog = () => {
    const title = "Deactivate Haunt";
    const body = (
      <>
        <p className="text-sm text-gray-500 mb-2">
          We're sorry to see you go, but we understand that circumstances
          change.
        </p>
        <p className="text-sm text-gray-500 mb-2">
          We hope to see you back soon, and thank you for being part of our
          community!
        </p>
        <p className="text-sm text-gray-500 mb-2">
          <strong>Please note:</strong> Deactivating your haunt will not{" "}
          <strong>delete</strong> it; it will simply be removed from our
          listings. You can reactivate it at any time.
        </p>
      </>
    );

    setDialogContent({
      title,
      body,
      action: handleDeactivate,
      yesButtonText: "Deactivate",
      loadingText: "Deactivating",
    });

    setIsDialogOpen(true);
  };

  // TODO: FIXME: This is not working.  It is not opening the dialog, instead returns a warning or a loop
  // MyDialog seems to be working though...
  const openWelcomeBackDialog = () => {
    // console.log("openWelcomeBackDialog");

    const title = "Welcome Back!";
    const body = (
      <>
        <p className="text-sm text-gray-500 mb-2">
          In order to keep the Haunt Map listings up to date, we are asking each
          haunt owner to <span className="font-bold">"reactivate"</span> their
          haunt.
        </p>
        <p className="text-sm text-gray-500 mb-2">
          If your haunt will be open this year (2024), simply click the
          "Reactivate" button and you will automatically be re-added to the
          site.
        </p>
        <p className="text-sm text-gray-500">
          If you are not ready to reactivate your haunt, just click the "No"
          button. You will be able to reactivate it at another time.
        </p>
      </>
    );

    setDialogContent({
      title,
      body,
      action: handleActivate,
      yesButtonText: "Yes, Reactivate",
      onClose: () => {
        setCookie("welcomeBackDialogShown", "true", 30);
        setIsDialogOpen(false);
      },
    });

    setIsDialogOpen(true);
  };

  const onCloseWelcomeBackOnClose = () => {
    // console.log(`onCloseWelcomeBackOnClose`);
  };

  const openDeleteDialog = () => {
    const title = "Delete Haunt";
    const body = (
      <>
        <p className="text-sm text-gray-500 mb-2">
          Are you sure you want to delete this location?
        </p>
        <p className="text-sm text-gray-500 mb-2">
          Note, this is irreversible and you will have to re-add your haunt
          again
        </p>
      </>
    );

    setDialogContent({
      title,
      body,
      action: handleDelete,
      yesButtonText: "Delete",
    });
    setIsDialogOpen(true);
  };

  const handleModalClose = () => {
    // console.log("handleModalClose");
    modalOpen ? setModalState(false) : null;
  };

  const updateMyHaunt = (data) => {
    // console.log("🎃 update myHaunt", data);
    setMyHaunt(data);
  };

  function deleteLocation() {
    return API.del("locations", `/locations/${myHaunt.SK}`, null);
  }

  // Call from openDeleteDialog
  const handleDelete = async (event) => {
    event.preventDefault();

    // console.log("handleDelete");

    setIsDeleting(true);

    try {
      const response = await deleteLocation();

      if (response.status) {
        setHasHaunt(false);
        setMyHaunt({});

        setReloadLocations(true);
        setIsLoading(false);

        history.push("/");

        toast.success("Successfully deleted your location 🪦");
      } else {
        setReloadLocations(false);
        setIsLoading(false);
      }
    } catch (e) {
      onError(e);
      setIsDeleting(false);
    }
  };

  const handleEdit = () => {
    //console.log("handleEdit, what do I need to do. 🤔");
    history.push(`/locations/edit/${myHaunt.SK}`);
    // setIsEditing(true);
  };

  const makeApiCall = async (api) => {
    const response = await API.put(
      "locations",
      `/locations/${api}/${myHaunt.SK}`,
      { headers: { Accept: "application/json" }, response: true }
    );

    return response.data;
  };

  /* Image Handlers */
  async function deleteLocationImage(locationInfo) {
    // This deletes the image from the DynamoDB table
    return API.del("locations", `/locations/image/${myHaunt.SK}`, {
      headers: { Accept: "application/json" },
      response: true,
    });
  }

  async function addImageToDynamoDbEntry(imageName) {
    // This adds the image from the DynamoDB table
    return API.put("locations", `/locations/image/${myHaunt.SK}`, {
      headers: { Accept: "application/json" },
      body: imageName,
      response: true,
    });
  }

  function deleteImageFromS3Bucket() {
    // This deletes the image from the S3 bucket.
    return API.del("image", `/file/${myHaunt.image}`, {
      headers: { Accept: "application/json" },
      response: true,
    });
  }

  const handleImageUpload = async () => {
    /**
     * - Need to check the file for size and type
     * - set the file name to the userId minus the region.  'us-east-1:32fb68bd-0678-4db0-adba-56efe07a3f55'
     * - Upload the file to the /upload endpoint
     * - Add the image field in the database containing the URL to the image
     */

    // console.log("🟡 handleImageUpload - filename", myHaunt.userId);

    // TODO: convert to modal dialog
    const fileInput = document.createElement("input");
    fileInput.type = "file";
    fileInput.accept = "image/*";
    fileInput.onchange = async (event) => {
      const file = event.target.files[0];
      if (!file) return;

      // Check if the selected file is an image
      const validImageTypes = [
        "image/gif",
        "image/jpeg",
        "image/png",
        "image/webp",
      ];

      if (!validImageTypes.includes(file.type)) {
        toast.error("Please select a valid image file (gif, jpeg, png, webp)");
        return;
      }

      const fileExtension = file.name.split(".").pop().toLowerCase();

      try {
        // This sets the spinner on the add image button
        setIsAddingImage(true);

        const formData = new FormData();

        const customFileName = `${myHaunt.SK}.${fileExtension}`;

        formData.append("file", file, customFileName);

        // Upload the image to the S3 bucket
        const response = await API.post("image", `/file/upload`, {
          body: formData,
          headers: { Accept: "application/json" },
          response: true,
        });

        if (response.status === 200) {
          const imageUrl = response.data.link;

          // Now we need to update the DynamoDB entry with the image URL
          const addLocationImageResponse = await addImageToDynamoDbEntry(
            customFileName
          );

          if (
            addLocationImageResponse.status === 200 ||
            addLocationImageResponse.status === 204
          ) {
            // This sets the spinner on the add image button
            setIsAddingImage(false);
            setMyHaunt(addLocationImageResponse.data);
            toast.success("Successfully added image");
          }
        } else {
          // This sets the spinner on the add image button
          setIsAddingImage(false);
          toast.error("Error removing image");
        }
      } catch (error) {
        // This sets the spinner on the add image button
        setIsAddingImage(false);
        // console.error("🔴 Error uploading image", error);
        toast.error("Error uploading image");
      }
    };

    fileInput.click();
  };

  const handleImageDelete = async (event) => {
    event.preventDefault();

    // TODO: convert to modal dialog
    const confirmed = window.confirm(
      "Are you sure you want to delete your image? \n\nNote, this is irreversible and you will have to re-upload your image again"
    );

    if (!confirmed) {
      return;
    }

    // This sets the spinner on the delete button
    setIsDeletingImage(true);

    // TODO: Replace the "profile" card with a "loading" card when adding/deleting the image.

    try {
      // Delete the image from the S3 bucket
      const response = await deleteImageFromS3Bucket();

      // If image is successfully deleted from S3, then we need to update the database
      if (response.status === 200) {
        const removeLocationImageResponse = await deleteLocationImage();

        if (
          removeLocationImageResponse.status === 200 ||
          removeLocationImageResponse.status === 204
        ) {
          setMyHaunt(removeLocationImageResponse.data);
          toast.success("Successfully removed image");
        }
      } else {
        toast.error("Error removing image");
      }

      setIsLoading(false);
      setIsDeletingImage(false);
    } catch (e) {
      // console.error("🔴🔴 Error deleting image", e);
      onError(e);
      setIsDeletingImage(false);
    }
  };
  // End Image Handlers

  // Activate/Deactivate Handlers
  const handleActivate = async () => {
    setIsLoading(true);

    // console.log("make reactivate API call");
    const data = await makeApiCall("activate");

    updateMyHaunt(data);

    handleModalClose();
    setIsLoading(false);

    toast.success("Successfully Activated your location 🎃");
  };

  const handleDeactivate = async () => {
    setIsLoading(true);

    const data = await makeApiCall("deactivate");

    updateMyHaunt(data);

    setIsDialogOpen(false);

    toast.success("Successfully Deactivated your location");
  };
  // End Activate/Deactivate Handlers

  // Is this used?  If so, when/where?
  const displayEditForm = () => {
    return (
      <>
        <div className="w-full max-w-xl mx-auto mb-4">
          <div className="shadow-md rounded-md overflow-hidden bg-white text-gray-900"></div>
        </div>
      </>
    );
  };

  /* TODO: Add, and style this section */
  const displayAddHaunt = () => {
    return (
      <div className="w-full max-w-xl mx-auto mb-4">
        <div className="shadow-md rounded-md overflow-hidden bg-white text-gray-900 pb-2">
          <div className="p-3">
            <h3 className="text-black text-2xl font-semibold mb-1 flex gap-2 items-center">
              Welcome to AZ Haunt Map!
            </h3>
          </div>
          <div className="p-3 text-center">
            <p className="mb-12">
              It appears that you do not have a haunt listed on the site. Please
              click the button below if you would like to add your haunt now.
            </p>
            <NavLink
              to="/locations/add"
              className="w-full md:w-auto text-sm bg-yellow-600 px-8 py-2 text-white rounded font-medium md:text-lg hover:bg-gray-500"
            >
              Add your Haunt now!
            </NavLink>
          </div>
        </div>
      </div>
    );
  };

  const displayLocationCard = () => {
    // TODO 2024-09-23: Replace this with the <Card> component
    return (
      <>
        <div className="w-full max-w-xl mx-auto mb-4">
          <div className="p-3">
            <h3 className="text-white text-2xl font-semibold mb-10 ">
              Welcome back {myHaunt.contact.firstName}
            </h3>
          </div>
          <div className="">
            <p className="text-white text-xl font-semibold mb-5 flex gap-2 items-center">
              Your Haunt information:
            </p>

            <div className="shadow-md rounded-md overflow-hidden bg-white text-gray-900">
              <div className="p-3">
                <h3 className="text-black text-2xl font-semibold mb-1 flex gap-2 items-center">
                  <Icons width={20} type="haunt" /> {myHaunt.name}
                </h3>

                <address className="italic flex flex-col mb-4 text-sm text-gray-600">
                  {myHaunt.address.street}
                  <br />
                  {myHaunt.address.city}, {myHaunt.address.state}{" "}
                  {myHaunt.address.zip}
                </address>

                {myHaunt.description && (
                  <div className="description mb-6">{myHaunt.description}</div>
                )}

                {myHaunt.openDate && (
                  <div className="openingDate mb-4">
                    Opening Day:
                    <br />
                    {myHaunt.openDate}
                  </div>
                )}

                {myHaunt.hours && (
                  <div className="hours mb-4">
                    Hours:
                    <br />
                    {myHaunt.hours}
                  </div>
                )}
              </div>

              {/* Action Button area */}
              <div className="p-3 bg-gray-100 flex justify-between">
                <LoaderButton
                  type={"button"}
                  className="text-red-500 hover:text-red-700 font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline flex justify-between w-auto gap-4 items-center"
                  onClick={handleEdit}
                  isLoading={isEditing}
                >
                  Edit
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-6 w-6"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M15.232 5.232l3.536 3.536m-2.036-5.036a2.5 2.5 0 113.536 3.536L6.5 21.036H3v-3.572L16.732 3.732z"
                    />
                  </svg>
                </LoaderButton>

                {/* If there is no image, then display the upload image button, if there is an image, then display remove image button */}
                {featureHauntImages && !myHaunt.image && (
                  <LoaderButton
                    type={"button"}
                    className="text-red-500 hover:text-red-700 font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline flex justify-between w-auto gap-4 items-center"
                    onClick={handleImageUpload}
                    isLoading={isAddingImage}
                  >
                    Add Image
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="h-6 w-6"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="m2.25 15.75 5.159-5.159a2.25 2.25 0 0 1 3.182 0l5.159 5.159m-1.5-1.5 1.409-1.409a2.25 2.25 0 0 1 3.182 0l2.909 2.909m-18 3.75h16.5a1.5 1.5 0 0 0 1.5-1.5V6a1.5 1.5 0 0 0-1.5-1.5H3.75A1.5 1.5 0 0 0 2.25 6v12a1.5 1.5 0 0 0 1.5 1.5Zm10.5-11.25h.008v.008h-.008V8.25Zm.375 0a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0Z"
                      />
                    </svg>
                  </LoaderButton>
                )}

                {/* TODO: Conditionally replace the icon with spinner */}
                {featureHauntImages && myHaunt.image && (
                  <LoaderButton
                    type={"button"}
                    className="text-red-500 hover:text-red-700 font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline flex justify-between w-auto gap-4 items-center"
                    onClick={handleImageDelete}
                    // isLoading={isDeletingImage}
                  >
                    Remove Image
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="h-6 w-6"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="m2.25 15.75 5.159-5.159a2.25 2.25 0 0 1 3.182 0l5.159 5.159m-1.5-1.5 1.409-1.409a2.25 2.25 0 0 1 3.182 0l2.909 2.909m-18 3.75h16.5a1.5 1.5 0 0 0 1.5-1.5V6a1.5 1.5 0 0 0-1.5-1.5H3.75A1.5 1.5 0 0 0 2.25 6v12a1.5 1.5 0 0 0 1.5 1.5Zm10.5-11.25h.008v.008h-.008V8.25Zm.375 0a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0Z"
                      />
                    </svg>
                  </LoaderButton>
                )}

                <LoaderButton
                  type={"button"}
                  className="text-red-500 hover:text-red-700 font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline flex justify-between w-auto gap-4 items-center"
                  // onClick={handleDelete}
                  onClick={openDeleteDialog}
                  isLoading={isDeleting}
                >
                  Delete
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-6 w-6"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
                    />
                  </svg>
                </LoaderButton>

                {!myHaunt.isActive && (
                  <LoaderButton
                    isLoading={isLoading}
                    className="inline-flex w-full justify-center rounded-md border border-transparent bg-green-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2 sm:ml-3 sm:w-auto sm:text-sm"
                    onClick={handleActivate}
                  >
                    Reactivate
                  </LoaderButton>
                )}

                {/* TODO: Add a "deactivate" modal explaining what this functionality does. */}
                {myHaunt.isActive && (
                  // Testing out the CustomDDialog
                  // <button onClick={openDeactivateDialog}>Deactivate</button>
                  <LoaderButton
                    isLoading={isLoading}
                    className="inline-flex w-full justify-center rounded-md border border-transparent bg-red-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2 sm:ml-3 sm:w-auto sm:text-sm"
                    // onClick={handleDeactivate}
                    onClick={openDeactivateDialog}
                    // onClick={() => {
                    //   openDeactivateDialog(
                    //     setDialogContent,
                    //     setIsDialogOpen,
                    //     handleDeactivate
                    //   );
                    // }}
                  >
                    Deactivate
                  </LoaderButton>
                )}
              </div>
              {/* {myHaunt.isActive && (
                <div className="px-4 py-2 text-red-900 ">
                  <p>
                    <strong>Note:</strong> Deactivating your haunt does not
                    delete it, only removes it from the listings. You can
                    "reactivate" it anytime.
                  </p>
                </div>
              )} */}
            </div>
          </div>
        </div>
      </>
    );
  };

  const handleDialogClose = () => {
    setDialogContent({});
    setIsDialogOpen(false);
  };

  return (
    <div className="profile">
      {isLoading && !hasHaunt && (
        <div className="flex flex-col gap-6 justify-center items-center mt-4">
          Loading...
          <svg
            className="animate-spin h-5 w-5"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
          >
            <circle
              className="opacity-25"
              cx="12"
              cy="12"
              r="10"
              stroke="currentColor"
              strokeWidth="4"
            />
            <path
              className="opacity-75"
              fill="currentColor"
              d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
            />
          </svg>
        </div>
      )}

      {/* Add Haunt section */}

      {!isLoading && !hasHaunt && displayAddHaunt()}

      {hasHaunt && !isEditing && displayLocationCard()}

      {hasHaunt && isEditing && displayEditForm()}

      <CustomDialog
        isOpen={isDialogOpen}
        onClose={
          dialogContent.onClose ||
          (() => {
            // setDialogContent({});
            setIsDialogOpen(false);
          })
        }
        onYes={dialogContent.action}
        title={dialogContent.title}
        isLoading={isLoading}
        yesButtonText={dialogContent.yesButtonText}
        loadingText={dialogContent.loadingText}
      >
        {dialogContent.body}
      </CustomDialog>
    </div>
  );
}
