import { useEffect, useState } from "react";
import { Auth } from "aws-amplify";
import { useAppContext } from "../lib/contextLib";
import LoaderButton from "../components/LoaderButton";
import { onError } from "../lib/errorLib";
import { useFormFields } from "../lib/hooksLib";
import { Link, useHistory } from "react-router-dom";

const LoginView = () => {
  const { userHasAuthenticated, setUserInfo } = useAppContext();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");
  const [fields, handleFieldChange] = useFormFields({
    email: "",
    password: "",
  });

  const history = useHistory();

  useEffect(() => {
    global.trackAnalyticsPageView(`/login`, "Login");
  }, []);

  function validateForm() {
    return fields.email.length > 0 && fields.password.length > 0;
  }

  async function handleSubmit(event) {
    event.preventDefault();

    setIsLoading(true);

    try {
      await Auth.signIn(fields.email, fields.password);

      // Retrieve the user.id
      const uInfo = await Auth.currentUserInfo();
      // console.log("uInfo", uInfo);

      // Retrieve the user's email
      const authUserInfo = await Auth.currentAuthenticatedUser();
      // console.log('authUserInfo', authUserInfo);

      setUserInfo((prevState) => ({
        ...prevState,
        id: uInfo.id,
        email: authUserInfo.attributes.email,
      }));

      userHasAuthenticated(true);

      history.push("/profile");
    } catch (e) {
      setError(
        onError({
          message: e.message ?? "There was a problem logging you in.  Please try again.",
        })
      );
      setIsLoading(false);
    }
  }

  return (
    <div className="loginView py-4">
      <div className="w-full max-w-sm mx-auto">
        {error && (
          <div className="error border-red-700 bg-red-100 text-gray-900 p-4 mb-2">
            <div className=" flex gap-2 items-center">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-6 w-6"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z"
                />
              </svg>
              <h3 className="font-bold text-lg">Error</h3>
            </div>
            {error}
          </div>
        )}
        <form
          onSubmit={handleSubmit}
          className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4 text-gray-500"
        >
          <h2 className="text-2xl text-gray-700 mb-4">Login</h2>
          <div className="mb-4">
            <label
              className="block text-gray-700 text-sm font-bold mb-2"
              htmlFor="email"
            >
              Email
            </label>
            <input
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-md focus:ring focus:ring-blue-100"
              autoFocus={true}
              id="email"
              type="text"
              placeholder="Email"
              onChange={handleFieldChange}
            />
          </div>

          <div className="mb-6">
            <label
              className="block text-gray-700 text-sm font-bold mb-2"
              htmlFor="password"
            >
              Password
            </label>
            <input
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-md focus:ring focus:ring-blue-100"
              id="password"
              type="password"
              placeholder="******************"
              onChange={handleFieldChange}
            />
            <div className="text-sm">
              Forgot your password?{" "}
              <Link
                to="/login/reset"
                className="text-yellow-500 hover:text-yellow-600 hover:underline"
              >
                Reset password
              </Link>
            </div>
          </div>

          <div className="flex items-center justify-between">
            <div className="text-sm">
              No account?{" "}
              <Link
                to="/signup"
                className="text-yellow-500 hover:text-yellow-600 hover:underline"
              >
                Signup
              </Link>
            </div>
            <LoaderButton
              isLoading={isLoading}
              disabled={!validateForm()}
              onClick={() => {
                setError(null);
              }}
              type="submit"
              className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-md focus:ring focus:ring-blue-100 flex justify-between w-auto gap-4 items-center"
            >
              Login
            </LoaderButton>
          </div>
        </form>
      </div>
    </div>
  );
};

export default LoginView;
