import React from 'react';
import {Marker} from "@react-google-maps/api";

const MapMarker = (props) => {

/* TODO: Finish flushing this out
    if (props.place.type === "yard") {
      return (
        <Marker
        key={props.place.SK}
        position={props.place.pos}
        onLoad={props.loadHandler}
        onClick={props.clickHandler}
        icon={{
          // 30x41
          url: require("../images/marker-display.png"),
        }}
      />
      )
    }

    if (props.place.type === "walkThrough") {
      return (
        <Marker
        key={props.place.SK}
        position={props.place.pos}
        onLoad={props.loadHandler}
        onClick={props.clickHandler}
        icon={{
          // 29x41
          url: require("../images/marker-walkthrough.png"),
          // scaledSize: {
          //   width: 48,
          //   height: 48
          // }
        }}
      />
      )
    }

    if (props.place.type === "pro") {
      return (
        <Marker
        key={props.place.SK}
        position={props.place.pos}
        onLoad={props.loadHandler}
        onClick={props.clickHandler}
        icon={{
          // 30x41
          url: require("../images/marker-paid.png"),
          // scaledSize: {
          //   width: 48,
          //   height: 48
          // }
        }}
      />
      )
    }
*/

    return (
        <Marker
            key={props.place.SK}
            position={props.place.pos}
            onLoad={props.loadHandler}
            onClick={props.clickHandler}
            icon={{
                path: "M17.52,14.16l1.23.54V20l-.31.08.31.29v1.44H0V17.17l.23-.12L0,16.92V14.7L3.92,13V8.18H1.58L5.76,4.86,5,3.84,4.77,2.39H5.88v1l.49.85h0L9.38,0l3.53,4.54,4.27,3.64H14.83V13l2.5,1.11m-6.65,3.14H8v4.6h2.65Zm-5,1.25H4.41v1.31H5.65Zm-1.74,0H2.67v1.31H3.91Zm12.48,0H15.15v1.31h1.24Zm-1.74,0H13.41v1.31h1.24ZM3.91,16.79H2.67V18.1H3.91Zm1.74,0H4.41V18.1H5.65Zm9,0H13.41V18.1h1.24Zm1.74,0H15.15V18.1h1.24Zm-8.71-4.1H7v1.12h.69Zm-1,0H6v1.12h.69Zm6.34,0h-.69v1.12h.69Zm-1,0h-.69v1.12h.69ZM6.71,11.25H6v1.12h.69Zm1,0H7v1.12h.69Zm4.4,0h-.69v1.12h.69Zm1,0h-.69v1.12h.69Zm-3.79-3H8.54a.86.86,0,0,0,.72.66Zm1,0H9.54v.66A.87.87,0,0,0,10.21,8.28ZM9.26,8V7.25A.87.87,0,0,0,8.53,8Zm1,0a.85.85,0,0,0-.68-.74V8Z",
                fillColor: "#f47c00",
                fillOpacity: 1.0,
                strokeWeight: 0.25,
                scale: 1,
                anchor: new google.maps.Point(0, 15),
            }}
        />
    );
};

export default MapMarker;
