import { useAppContext } from "../lib/contextLib";
import { useEffect, useState } from "react";
import Card from "../components/Card";

const ListViewCard = () => {
  const { locations } = useAppContext();
  const [search, setSearch] = useState("");

  useEffect(() => {
    global.trackAnalyticsPageView("/listing", "List View");
  }, []);

  const locationsList = locations
    .filter((data) => {
      if (search == null) {
        return data;
      }

      if (
        data.name.toLowerCase().includes(search.toLowerCase()) ||
        data.address.street.toLowerCase().includes(search.toLowerCase()) ||
        data.address.city.toLowerCase().includes(search.toLowerCase()) ||
        data.address.zip.includes(search.toLowerCase())
      ) {
        return data;
      }
    })
    .map((location) => <Card location={location} key={location.SK} />);

  return (
    <div className="flex justify-center items-center flex-col">
      {locations.length === 0 && (
        <div className="text-lg flex justify-items-center items-center">
          No locations found.
        </div>
      )}
      {locations.length > 0 && (
        <>
          <input
            placeholder="Filter by name/street/city/zip..."
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            className="p-3 text-gray-900 w-full md:w-2/3 xl:w-1/3 mb-8 rounded"
          />
          <div className="w-full mb-4 grid grid-cols-1 md:grid-cols-2 md:grid-flow-row lg:grid-cols-3 xl:grid-cols-3 2xl:grid-cols-6 gap-4">
            {locationsList}
          </div>
        </>
      )}
    </div>
  );
};

export default ListViewCard;
