import React, { useEffect, useState } from "react";
import { Auth } from "aws-amplify";
import { Link } from "react-router-dom";
import LoaderButton from "../components/LoaderButton";
import { useFormFields } from "../lib/hooksLib";
import { onError } from "../lib/errorLib";
import { BsCheck, BsEye, BsEyeSlash } from "react-icons/bs";
import InputField from "../components/InputField";
import { passwordHint } from "../components/organisms/passwordHint";

export default function ResetPassword() {
  const [fields, handleFieldChange] = useFormFields({
    code: "",
    email: "",
    password: "",
    confirmPassword: "",
  });

  const [codeSent, setCodeSent] = useState(false);
  const [confirmed, setConfirmed] = useState(false);

  const [errorMessage, setErrorMessage] = useState("");
  const [errorCode, setErrorCode] = useState("");

  const [isLoading, setIsLoading] = useState(false);
  const [passwordFieldType, togglePasswordFieldType] = useState('password');

  useEffect(() => {
    if (!codeSent || errorCode === "ExpiredCodeException") {
      global.trackAnalyticsPageView(`/login/reset`, "forgotPassword: requestCode");
    }

    if (codeSent && !confirmed) {
      global.trackAnalyticsPageView(`/login/reset`, "forgotPassword: resetPassword");
    }

    if (codeSent && confirmed) {
      global.trackAnalyticsPageView(`/login/reset`, "forgotPassword: success");
    }
  }, [codeSent, confirmed, errorCode]);


  const validateEmail = (email) => {
    return /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/i.test(email);
  }

  const passwordValidate = () => {
    const regex = new RegExp('^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*_=+-]).{8,}$');
    return regex.test(fields.password) && regex.test(fields.confirmPassword);
  }

  const passwordsNotMatching = () => (
    <div className="text-sm text-red-900 pl-2">
      <div className="">
        Password and Confirm Password do not match.
      </div>
    </div>
  );


  function validateGetResetCodeForm() {
    return validateEmail(fields.email);
  }

  const getErrorMessage = (errorCode) => {
    switch (errorCode) {
      case "LimitExceededException":
        return `Too many attempts.  Please try again in a few minutes.`
      case "CodeMismatchException":
        return `The code you entered is incorrect.  Please try again.`
      case "ExpiredCodeException":
        return `The code you entered has expired.  Please request a new code.`
      default:
        return `There was a problem resetting your password.  Please try again.`
    }
  }

  function validateResetForm() {
    return (
      fields.code.length > 0 &&
      fields.password.length >= 8 &&
      fields.password === fields.confirmPassword &&
      passwordValidate() // Redundant, but a safety check
    );
  }

  async function handleSendCodeClick(event) {
    event.preventDefault();

    setIsLoading(true)

    try {
      await Auth.forgotPassword(fields.email);
      setCodeSent(true);
      setIsLoading(false);
    } catch (error) {
      setErrorMessage(onError(error));
      setIsLoading(false);
    }
  }

  async function handleResetPasswordSubmit(event) {
    event.preventDefault();

    try {
      await Auth.forgotPasswordSubmit(
        fields.email,
        fields.code,
        fields.password
      );
      setConfirmed(true);
    } catch (error) {

      setErrorCode(error.code);

      setErrorMessage(
        onError({
          message: getErrorMessage(error.code) ?? `️There was a problem resetting your password.  Please try again in a few minutes.`,
        })
      );
    }
  }

  function renderRequestCodeForm() {
    return (
      <form onSubmit={handleSendCodeClick} className="flex flex-col gap-8">

        <h2 className="text-2xl text-gray-700 mb-4">Forgot Password</h2>

        <InputField
          id="email"
          labelText="Enter Email"
          onChange={handleFieldChange}
          type={"email"}
          value={fields.email}
          required={true}
        />

        <div className="flex justify-between gap-1 items-center">
          <div className="text-sm text-gray-500 hover:text-black cursor-pointer" onClick={() => setCodeSent(true)}>Have a code?</div>
          <LoaderButton
            type="submit"
            isLoading={isLoading}
            disabled={!validateGetResetCodeForm()}
            className="focus:outline-none focus:ring focus:ring-yellow-600 border border-transparent py-2 px-5 rounded-lg shadow-sm text-center text-white bg-blue-500 hover:bg-blue-600 font-medium flex justify-center items-center gap-2 disabled:opacity-60 disabled:bg-blue-400 disabled:cursor-default"
          >
            Send Confirmation
          </LoaderButton>
        </div>
      </form>
    );
  }

  function renderResetPasswordForm() {
    return (
      <form onSubmit={handleResetPasswordSubmit} className="flex flex-col gap-8">
        <h2 className="text-2xl text-gray-700 mb-4">Reset Password</h2>

        <h3 className="bg-blue-50 text-gray-400 px-4 py-2 rounded">
          Note: It may take a few minutes to receive the email with your code.
        </h3>

        <div className="mb-4">
          <InputField
            id="code"
            labelText="Confirmation Code"
            onChange={handleFieldChange}
            type={"tel"}
            value={fields.code}
            required={true}
          />
          <span className="pl-2 text-sm text-gray-600">
            Please check your email for the code.
          </span>
        </div>



        {/* Password Field */}
        <div className="mb-4">
          <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="password">
            Password
          </label>
          <div className="relative">
            <input
              className="shadow appearance-none border rounded w-full py-2 px-3 pr-8 mr-2 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              id="password"
              type={passwordFieldType}
              onChange={ handleFieldChange }
              value={fields.password}
            />

            {passwordFieldType === "text" && (
              <BsEye
                size={20}
                className="text-gray-500 absolute right-0 top-0 mt-2.5 mr-2"
                onClick={ () => { togglePasswordFieldType('password') }}
              />
            )}

            {passwordFieldType === "password" && (
              <BsEyeSlash
                size={20}
                className="text-gray-500 absolute right-0 top-0 mt-2.5 mr-2"
                onClick={ () => { togglePasswordFieldType('text') }}
              />
            )}

          </div>
          <>
            { passwordHint(fields) }
          </>
        </div>

        {/* Confirm Password Field */}
        <div className="mb-4">
          <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="confirmPassword">
            Confirm Password
          </label>
          <div className="relative">
            <input
              className="shadow appearance-none border rounded w-full py-2 px-3 pr-8 mr-2 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              id="confirmPassword"
              type={passwordFieldType}
              onChange={handleFieldChange}
              value={fields.confirmPassword}
            />
            {passwordFieldType === "text" && (
              <BsEye
                size={20}
                className="text-gray-500 absolute right-0 top-0 mt-2.5 mr-2"
                onClick={ () => { togglePasswordFieldType('password') }}
              />
            )}

            {passwordFieldType === "password" && (
              <BsEyeSlash
                size={20}
                className="text-gray-500 absolute right-0 top-0 mt-2.5 mr-2"
                onClick={ () => { togglePasswordFieldType('text') }}
              />
            )}

            {fields.password !== fields.confirmPassword && (
              <>
                {passwordsNotMatching()}
              </>
            )}
          </div>
        </div>

        <div className="flex justify-end">
          <LoaderButton
            isLoading={isLoading}
            disabled={ !validateResetForm() }
            className="focus:outline-none focus:ring focus:ring-yellow-600 border border-transparent py-2 px-5 rounded-lg shadow-sm text-center text-white bg-blue-500 hover:bg-blue-600 font-medium flex justify-center items-center gap-2 disabled:opacity-60 disabled:bg-blue-400 disabled:cursor-default"
          >
            Reset Password
          </LoaderButton>
        </div>
      </form>
    );
  }

  function renderSuccessMessage() {
    return (
      <div className="success">
        <p>
          <BsCheck size={16} /> Your password has been reset.
        </p>
        <p>
          <Link to="/login">
            Click here to login with your new credentials.
          </Link>
        </p>
      </div>
    );
  }

  const displayErrorMessage = (message) => {
    return (
      <div className="error border-red-700 bg-red-100 text-red-900 p-4 mb-2 m-auto w-full max-w-sm rounded">
        <div className=" flex gap-2 items-center">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-6 w-6"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z"
            />
          </svg>
          <h3 className="font-bold text-lg">Error</h3>
        </div>
        {message}
      </div>
    )
  }

  return (
    <div className="ResetPassword py-4">
      {errorMessage && (
        displayErrorMessage(errorMessage)
      )}

      <div className="w-full max-w-sm mx-auto bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4">
        {/* TODO: Check this to see if it is finished.
          If the code has expired, then we want to show the Request New Code form and display a message saying the code has expired.

          View Display logic:
          1. Display Request code form
          2. Display Reset Password Form
            If error:
            a. Code Expired: Display Request New Code form and display message saying code has expired.
            b. Display error message related to reset password form.
        */}

        {!codeSent
          ? renderRequestCodeForm()
          : !confirmed
          ? renderResetPasswordForm()
          : renderSuccessMessage()}
      </div>
    </div>
  );
}
