import React, { useRef, useState, useEffect } from "react";
import {useParams, useHistory, NavLink} from "react-router-dom";
import { API, Storage } from "aws-amplify";
import config from "../amplify_config";
import { s3Upload } from "../lib/awsLib";

import { onError } from "../lib/errorLib";
// import States from "../components/States";
import LoaderButton from "../components/LoaderButton";
// import Card from "../components/Card";
// import HauntStreetImage from "../images/hauntstreet.jpg";
import Icons from "../components/Icons";
import {useAppContext} from "../lib/contextLib";


export default function Location() {
    console.log('location.js');

    const { userInfo, setReloadLocations } = useAppContext();
    const file = useRef(null);
    const { id } = useParams();
    const history = useHistory();
    const [location, setLocation] = useState(null);
    const [content, setContent] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [isDeleting, setIsDeleting] = useState(false);
    const [isEditing, setIsEditing] = useState(false);
    /*
    * TODO: Look into caching
    * */
    useEffect(() => {
        console.log("id", id)
        onLoad();
    }, [id]);

    async function loadLocation() {
        return API.get("locations", `/locations/${id}`, null);
    }

    async function onLoad() {
        try {
            const location = await loadLocation();
            const { content, attachment } = location;

            if (attachment) {
                location.attachmentURL = await Storage.vault.get(attachment);
            }

            setContent(content);
            setLocation(location);

            console.log('location', location);

        } catch (e) {
            onError(e);
        }
    }

    function validateForm() {
        return false;
        // return content.length > 0;
    }

    function formatFilename(str) {
        return str.replace(/^\w+-/, "");
    }

    function handleFileChange(event) {
        file.current = event.target.files[0];
    }

    function saveLocation(note) {
        return API.put("locations", `/locations/${id}`, {
            body: note
        });
    }

    async function handleSubmit(event) {
        let attachment;

        event.preventDefault();

        if (file.current && file.current.size > config.MAX_ATTACHMENT_SIZE) {
            alert(
                `Please pick a file smaller than ${config.MAX_ATTACHMENT_SIZE /
                1000000} MB.`
            );
            return;
        }

        setIsLoading(true);

        try {
            // TODO: This adds the same file, need to look into a way to delete any existing file.
            if (file.current) {
                attachment = await s3Upload(file.current);
            }

            await saveLocation({
                content,
                attachment: attachment || location.attachment
            });
            history.push("/");
        } catch (e) {
            onError(e);
            setIsLoading(false);
        }
    }

    function deleteLocation() {
        console.log(`deleteLocation ${id}`);
        return false;
        return API.del("locations", `/locations/${id}`);
    }

    async function handleDelete(event) {
        event.preventDefault();

        console.log('handleDelete')

        const confirmed = window.confirm(
            "Are you sure you want to delete this location? \n\nNote, this is irreversible."
        );

        if (!confirmed) {
            console.log('confirm handleDelete...');
            return;
        }

        setIsDeleting(true);

        try {
            // TODO: This only deletes it from the DB, need to add functionality to remove attached files
            await deleteLocation();

            setReloadLocations(true);
            setIsLoading(false);

            history.push("/");
        } catch (e) {
            onError(e);
            setIsDeleting(false);
        }
    }

    const handleEdit = () => {
        console.log('handleEdit');
    }

    return (
        <div className="Location">



            {/*
            TODO: Add logic to see if the logged in user is the location owner, if so then display edit form, otherwise
              display details view.
            */}
            {location && (
                <>
                    <div className="w-full max-w-xl mx-auto mb-4">
                        {/* TODO: Extract this to its own component
                        <Card location={location} />
                        */}


                        <div className="shadow-md rounded-md overflow-hidden bg-white text-gray-900">
                            {location.attachment && (
                                // TODO: get img url and display
                                // <img className="mb-4 h-48 w-full rounded-tl rounded-tr" src={location.attachmentURL} />
                                // <img className="object-cover object-top h-48 w-full rounded-tl rounded-tr" src={HauntStreetImage} />

                                <img src={location.attachmentURL} alt=""/>
                            )}

                            <div className="p-3">
                                <h3 className="text-black text-2xl font-semibold mb-1 flex gap-2 items-center"><Icons width={20} type="haunt" /> {location.name}</h3>

                                <address className="italic flex flex-col mb-4 text-sm text-gray-600">
                                    {location.address.street}<br />
                                    {location.address.city}, {location.address.state} {location.address.zip}
                                </address>

                                {location.description && (
                                    <div className="description mb-6">
                                        {location.description}
                                    </div>
                                )}

                                {location.openDate && (
                                    <div className="openingDate mb-4">
                                        Opens:<br/>
                                        {location.openDate}
                                    </div>
                                )}

                                {location.hours && (
                                    <div className="hours mb-4">
                                        Hours:<br/>
                                        {location.hours}
                                    </div>
                                )}
                            </div>

                            {location.attachment && (
                                <p className="text-blue-800 mb-4">


                                    <a
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        href={location.attachmentURL}
                                    >
                                        {formatFilename(location.attachment)}
                                    </a>
                                </p>
                            )}

                            <div className="p-3 bg-gray-100 flex justify-between">
                                <LoaderButton
                                    type={"button"}
                                    className="text-red-500 hover:text-red-700 font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline flex justify-between w-auto gap-4 items-center"
                                    onClick={handleEdit}
                                    isLoading={isEditing}
                                >
                                    Edit
                                    <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15.232 5.232l3.536 3.536m-2.036-5.036a2.5 2.5 0 113.536 3.536L6.5 21.036H3v-3.572L16.732 3.732z" />
                                    </svg>
                                </LoaderButton>

                                <LoaderButton
                                    type={"button"}
                                    className="text-red-500 hover:text-red-700 font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline flex justify-between w-auto gap-4 items-center"
                                    onClick={handleDelete}
                                    isLoading={isDeleting}
                                >
                                    Delete
                                    <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16" />
                                    </svg>
                                </LoaderButton>

                                <a href="https://www.google.com/maps/dir/?api=1&destination=33.4000575,-111.7528639"
                                   target="_blank"
                                   className="bg-gray-800 text-white active:bg-gray-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150 flex items-center gap-2 w-40"
                                >
                                    <Icons width={12} type="mapmarker" fillColor="orange" />
                                    Get Directions
                                </a>
                            </div>

                        </div>


                    </div>

{/*
                    <div className="w-full max-w-md mx-auto">



                        <form onSubmit={handleSubmit} className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4">

                            TODO: Complete the "update" piece, then expand the form to all the fields.
                              Look at adding "sections" for editing, i.e. a section for the address, section to update description, etc

                            TODO: Edit attachment

                            <h2 className="text-green-700 text-2xl mb-4">Edit { location.content }</h2>
                            <div className="mb-4">
                                <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="content">
                                    Address
                                </label>
                                <input
                                    className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                    autoFocus={true}
                                    id="content"
                                    type="text"
                                    placeholder="Street Address"
                                    required={true}
                                    value={content}
                                    onChange={(e) => setContent(e.target.value)}
                                />
                            </div>


                            <div className="mb-4">
                                <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="city">
                                    City
                                </label>
                                <input
                                    className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                    id="city"
                                    type="text"
                                    placeholder="City"
                                    onChange={handleFieldChange}
                                />
                            </div>

                            <div className="mb-4">
                                <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="state">
                                    State
                                </label>
                                <select
                                    name="state"
                                    id="state"
                                    className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                    onChange={handleFieldChange}
                                >
                                    <option key={'--'} value="">--</option>
                                    {
                                        States.map(st => (
                                            <option key={st.abbr} value={st.abbr}>{st.full}</option>
                                        ))
                                    }
                                </select>
                            </div>

                            <div className="mb-4">
                                <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="zipcode">
                                    Zip Code
                                </label>
                                <input
                                    className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                    id="zipcode"
                                    type="tel"
                                    placeholder="Zip Code"
                                    onChange={handleFieldChange}
                                />
                            </div>


                            <div className="mb-4">
                                <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="zipcode">
                                    Attach Logo
                                </label>
                                {location.attachment && (
                                    <p className="text-blue-800 mb-4">
                                        <a
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            href={location.attachmentURL}
                                        >
                                            {formatFilename(location.attachment)}
                                        </a>
                                    </p>
                                )}
                                <input
                                    className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                    id="file"
                                    type="file"
                                    onChange={handleFileChange}
                                />
                                <div className="text-sm">Uploading new file will delete the old one.</div>
                            </div>
                            <div className="buttonWrapper flex justify-between">
                                <LoaderButton
                                    type="submit"
                                    isLoading={isLoading}
                                    disabled={!validateForm()}
                                    className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline flex justify-between w-auto gap-4 items-center"
                                >
                                    Update Location
                                </LoaderButton>

                                <LoaderButton
                                    type={"button"}
                                    className="text-red-500 hover:text-red-700 font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline flex justify-between w-auto gap-4 items-center"
                                    onClick={handleDelete}
                                    isLoading={isDeleting}
                                >
                                    Delete
                                    <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16" />
                                    </svg>
                                </LoaderButton>

                            </div>
                        </form>
                    </div>
*/}
                </>
            )}
        </div>
    );
}