import { NavLink } from "react-router-dom";

import azhLogo from "../images/azhaunters-color.png";
import { useEffect } from "react";
import RegisterCTA from "../components/RegisterCTA";
import { useAppContext } from "../lib/contextLib";

const displayAddHaunt = () => {
  return (
    <div className="w-full max-w-xl mx-auto mb-4">
      <div className="shadow-md rounded-md overflow-hidden bg-white text-gray-900 pb-2">
        <div className="p-3">
          <h3 className="text-black text-2xl font-semibold mb-1 flex gap-2 items-center">
            Welcome to AZ Haunt Map!
          </h3>
        </div>
        <div className="p-3 text-center">
          <p className="mb-12">
            It appears that you do not have a haunt listed on the site. Please
            click the button below if you would like to add your haunt now.
          </p>
          <NavLink
            to="/locations/add"
            className="w-full md:w-auto text-sm bg-yellow-600 px-8 py-2 text-white rounded font-medium md:text-lg hover:bg-gray-500"
          >
            Add your Haunt now!
          </NavLink>
        </div>
      </div>
    </div>
  );
};

const HomeView = () => {
  const { locations, isAuthenticated, hasHaunt } = useAppContext();

  // console.log("isAuthenticated", isAuthenticated);

  useEffect(() => {
    global.trackAnalyticsPageView(`/`, "Home");
  }, []);

  return (
    <div className="homeView lg:w-3/4 xl:w-2/5 m-auto">
      <div className="md:grid md:grid-cols-2 w-full">
        <div className="col-span-2">
          <h2 className="px-8 text-2xl text-center md:text-left">
            Welcome to the Official AZHaunters' Haunt Listing site!
          </h2>
        </div>

        {locations.length === 0 && (
          <div className="col-span-2">
            <div className="text-center mt-8">
              No haunts have been added yet for this year, please check back!
            </div>
            <div className="mt-8 text-sm bg-gray-400 text-black rounded p-3">
              Note: if you listed your haunt last year, please login and follow
              the instructions to re-list your location.
            </div>
          </div>
        )}

        <div className="p-2 md:p-4 flex flex-col gap-16 md:gap-8">
          {locations.length === 0 && (
            <div className="text-center mt-8">
              No haunts yet, please check back!
            </div>
          )}

          {locations.length > 0 && (
            <div className="text-center mt-8">
              <NavLink
                to="/map"
                className="py-4 px-8 font-bold text-gray-100 hover:text-gray-50 bg-orange-500 hover:bg-orange-600 text-lg rounded w-40"
              >
                View Map
              </NavLink>
            </div>
          )}

          {/* Only display this if the user is not logged in */}
          {!isAuthenticated && (
            <div id="RegisterCTA" className="md:mt-12 flex justify-center">
              <RegisterCTA />
            </div>
          )}

          {isAuthenticated && !hasHaunt && displayAddHaunt()}
        </div>
        <div className="my-8 flex justify-center content-center">
          <div className="flex justify-center items-start">
            <a
              href="https://www.azhaunters.com?utm_source=azhauntmaps&utm_medium=cpc&utm_campaign=azhauntmaps"
              target="_blank"
              rel="noopener noreferrer"
              onClick={(e) => {
                global.trackAnalyticsEvent(
                  "link",
                  "External",
                  "AZ Haunters (logo)"
                );
              }}
            >
              <img
                src={azhLogo}
                id="azhlogo"
                className="rounded-full w-64"
                alt="AZ Haunters Logo"
              />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomeView;
